import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { MenuItem } from '@jpp/atoms/MenuItem/MenuItem';
import { EPageType } from 'common/typings/enums';

import './FooterMenu.scss';

export interface IFooterMenuProps {
  className?: string;
  menu: Core.IMenuItem[];
}

export const FooterMenu: FunctionComponent<IFooterMenuProps> = ({
  className,
  menu,
}) => {
  if (!menu || menu?.length === 0 || !Array.isArray(menu)) {
    return null;
  }

  return (
    <section className={classNames(className, 'FooterMenu')}>
      <ul className={classNames('FooterMenu__list')}>
        {menu.map(({ ID, ...rest }, index) => {
          return (
            <MenuItem
              {...rest}
              ID={ID}
              key={ID}
              pageType={rest.pageType as EPageType}
              className="FooterMenu__item"
              linkClassName="FooterMenu__link"
            />
          );
        })}
      </ul>
    </section>
  );
};
