import * as Sentry from '@sentry/node';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stripe } from 'stripe';

import Heading from '@jpp/atoms/Heading';
import Icon from '@jpp/atoms/Icon';
import Alert from '@jpp/molecules/Alert';
import { EAlertType } from '@jpp/molecules/Alert/Alert';
import Button from '@jpp/molecules/Button';
import { IBillingFormValues } from '@jpp/organisms/CheckoutForm/components/BillingFormFields/BillingFormFields';
import { IShippingFormValues } from '@jpp/organisms/CheckoutForm/components/DeliveryFormFields/DeliveryFormFields';
import { PaymentMethodLogo } from '@jpp/organisms/CheckoutForm/utils';
import { TTabGroupChildProps } from '@jpp/organisms/Tab/Group/TabGroup';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { getAuth } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { IIntentResponse } from 'common/redux/payment/types';
import { getCurrentUser } from 'common/redux/rootActions';
import { EPriority, ESize } from 'common/typings/enums';
import axios from 'common/utils/axios/internal';

import DefaultCardIcon from '../../../../../../assets/images/payment-types/defaultCard.png';
import { IWithStripeElements } from '../../../../../../hoc/withStripe';
import './SavedCards.scss';

const ROOT_CLASSNAME = 'SavedCards';

const SavedCards: React.FunctionComponent<
  TTabGroupChildProps & IWithStripeElements
> = ({ className, elements, stripe, parentClassName }) => {
  const dispatch = useDispatch();
  const {
    stripe_data,
    first_name,
    last_name,
    billing = {} as IBillingFormValues,
    shipping = {} as IShippingFormValues,
    email,
    phone,
  } = useSelector<IReduxState, IAuthStoreState>((state) => getAuth(state));
  const defaultStatus = {
    isVisible: false,
    type: EAlertType.Default,
    message: '',
  };
  const [status, setStatus] = React.useState(defaultStatus);
  const paymentMethods = stripe_data?.paymentMethods || [];
  const hasNoPaymentMethods = paymentMethods.length === 0;
  const cardElementOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: '#0C0C0C',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 'normal',
        fontStyle: 'normal',
        backgroundColor: '#FFFFFF',
      },
      invalid: {
        color: '#A8241A',
        iconColor: '#A8241A',
      },
    },
  };

  const handleCardDelete = async (method: Stripe.PaymentMethod) => {
    const confirmation = confirm(
      `Are you sure you want to delete the card ending "${method.card?.last4}"? If this card is tied to a subscription the automatic payment will stop working.`
    );
    if (!confirmation) {
      return;
    }
    try {
      await axios.delete(`/payment/method/${method.id}`);
      setStatus({
        isVisible: true,
        type: EAlertType.Success,
        message: 'Your card was successfully removed.',
      });
      dispatch(getCurrentUser());
    } catch (e) {
      Sentry.captureException(e);
      setStatus({
        isVisible: true,
        type: EAlertType.Error,
        message: 'An error occurred. Could not remove card at this time.',
      });
    }
  };

  const handleCardSave = async () => {
    try {
      const firstName =
        billing.billing_first_name ||
        shipping.shipping_first_name ||
        first_name;
      const lastName =
        billing.billing_last_name || shipping.shipping_last_name || last_name;
      const fullName = `${firstName} ${lastName}`;
      const data = {
        customer_email: email,
        customer_name: fullName,
        customer_phone: phone,
        shipping,
      };
      const { data: saveCardIntent } = await axios.post<IIntentResponse>(
        '/payment/setup-wallet',
        data
      );
      const card = elements.getElement(CardElement)!;
      const result = await stripe.confirmCardSetup(
        saveCardIntent.client_secret,
        {
          payment_method: {
            card,
          },
        }
      );
      if (result.error) {
        Sentry.captureException(result.error);
      }

      // const ownerInfo = {
      //   owner: {
      //     name: fullName,
      //     address: {
      //       line1: shipping.shipping_address_1,
      //       city: shipping.shipping_city,
      //       postal_code: shipping.shipping_postcode,
      //       country: shipping.shipping_country
      //     },
      //     email
      //   }
      // };

      // We need to create a source for subscriptions
      // const source = await stripe.createSource(card, ownerInfo);
      // console.log({ source });

      dispatch(getCurrentUser());
      card.clear();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  console.log(paymentMethods);

  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${parentClassName}__panel`
      )}
    >
      <div className={`${parentClassName}__panel-group`}>
        <h1 className={`${ROOT_CLASSNAME}__heading`}>Your Saved Cards</h1>

        <Alert
          className={`${ROOT_CLASSNAME}__alert ${ROOT_CLASSNAME}__alert--${status.type}`}
          isVisible={status.isVisible}
          alertType={status.type}
          onClose={() => setStatus(defaultStatus)}
          children={status.message}
        />

        {hasNoPaymentMethods ? (
          <p className={`${ROOT_CLASSNAME}__copy`}>
            Looks like you don't have any saved payment methods. Feel free to
            add your card details below and we will store them for future
            reference.
          </p>
        ) : (
          <ul className={`${ROOT_CLASSNAME}__list`}>
            {paymentMethods.map((method) => {
              if (!method) {
                return null;
              }

              const { id, card } = method;
              const brand = card?.brand;
              const imgSrc = brand ? PaymentMethodLogo[brand] : DefaultCardIcon;

              return (
                <li key={id} className={`${ROOT_CLASSNAME}__item`}>
                  <img
                    className={`${ROOT_CLASSNAME}__card-logo`}
                    src={imgSrc}
                    loading="lazy"
                    alt={`${brand || 'Default card'} logo`}
                  />
                  <div className={`${ROOT_CLASSNAME}__card-last4`}>
                    ****&nbsp;****&nbsp;****&nbsp;{card?.last4}
                  </div>
                  <div className={`${ROOT_CLASSNAME}__card-expires`}>
                    Exp.&nbsp;&nbsp;{`${card?.exp_month}/${card?.exp_year}`}
                  </div>

                  <button
                    type="button"
                    className={`${ROOT_CLASSNAME}__card-delete`}
                    onClick={() => handleCardDelete(method)}
                  >
                    <Icon.Close
                      className={`${ROOT_CLASSNAME}__card-delete-icon`}
                      inline={true}
                    />
                  </button>
                </li>
              );
            })}
          </ul>
        )}

        <p className={`${ROOT_CLASSNAME}__copy mt-2`}>
          <small>
            * Any cards used for subscriptions, if deleted, will cause your
            subscription products to <strong>not</strong> auto renew. For
            security reasons we cannot tell you which cards are attached to your
            subscription orders.
          </small>
        </p>

        <div className={`${ROOT_CLASSNAME}__add-card`}>
          <Heading
            priority={EPriority.Two}
            className={`${ROOT_CLASSNAME}__sub-heading`}
          >
            Add a new card
          </Heading>

          <div className={`${ROOT_CLASSNAME}__card-input-wrapper`}>
            <CardElement
              className={classNames(`${ROOT_CLASSNAME}__card-input`)}
              options={cardElementOptions}
            />
          </div>

          <Button
            type="button"
            onClick={handleCardSave}
            className={`${ROOT_CLASSNAME}__cta-save`}
            behaviour="action"
            size={ESize.Xs}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SavedCards;
