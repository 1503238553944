import React from 'react';

import { ITabPanelProps } from '@jpp/molecules/Tab/Panel/types';
import { TTabGroupChildProps } from '@jpp/organisms/Tab/Group/TabGroup';

import { EMyAccountTab } from './MyAccountTabGroup';
import Dashboard from './panels/Dashboard';
import Details from './panels/Details';
import Orders from './panels/Orders';
import SavedCards from './panels/SavedCards';
import Subscriptions from './panels/Subscriptions';

export const getMyAccountTabPanels = (
  props: TTabGroupChildProps
): ITabPanelProps[] => [
  {
    slug: EMyAccountTab.Dashboard,
    isSelectable: false,
    children: <Dashboard {...props} />,
  },
  {
    slug: EMyAccountTab.Details,
    isSelectable: true,
    children: <Details {...props} />,
  },
  {
    slug: EMyAccountTab.SavedCards,
    isSelectable: true,
    label: 'Saved Cards',
    children: <SavedCards {...props} />,
  },
  {
    slug: EMyAccountTab.Orders,
    isSelectable: true,
    children: <Orders {...props} />,
  },
  {
    slug: EMyAccountTab.Subscriptions,
    isSelectable: true,
    children: <Subscriptions {...props} />,
  },
];
