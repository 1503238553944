import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import Heading from '@jpp/atoms/Heading';
import SocialMenu from '@jpp/molecules/SocialMenu';
import { EDisplayMode } from 'common/typings/enums';

import './OffCanvasFooter.scss';

interface IOffCanvasFooter {
  className?: string;
  company_slogan: string;
}

export const OffCanvasFooter: FunctionComponent<IOffCanvasFooter> = ({
  className,
  company_slogan,
}) => {
  return (
    <footer className={classNames('OffCanvasFooter', className)}>
      <SocialMenu
        className="OffCanvasFooter__social"
        displayMode={EDisplayMode.Inline}
      />

      <Heading priority={6} className="OffCanvasFooter__heading">
        {`"${company_slogan}"`}
      </Heading>
    </footer>
  );
};
