import classNames from 'classnames';
import React from 'react';

import { MenuItem } from '@jpp/atoms/MenuItem/MenuItem';
import { EPageType } from 'common/typings/enums';
import { TFuncValueVoid, TFuncVoid } from 'common/typings/types';

import './SubNavMenuItem.scss';

export interface ISubNavMenuItemProps {
  className?: string;
  linkClassName?: string;
  isMegaMenuItem?: boolean;
  isFirstItem?: boolean;
  onClick?: Core.TOnClick;
  iconPosition?: EIconPosition;
  pageType?: EPageType;
  onMouseEnter?: TFuncValueVoid<string>;
  onMouseLeave?: TFuncVoid;
}

export enum EIconPosition {
  Left = 'left',
  Right = 'right',
}

export type TSubNavMenuItem = ISubNavMenuItemProps & Core.IMenuItem;

const ROOT_CLASSNAME = 'SubNavMenuItem';

export const SubNavMenuItem: React.FunctionComponent<TSubNavMenuItem> = ({
  className,
  onMouseEnter: onMouseEnterFromParent,
  onMouseLeave: onMouseLeaveFromParent,
  isFirstItem,
  ...props
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const { childLinks, isMegaMenuItem, slug } = props;

  const onMouseEnter = () => {
    if (childLinks && isMegaMenuItem) {
      setIsVisible(true);
      setIsDirty(true);
    }

    if (onMouseEnterFromParent) {
      onMouseEnterFromParent(slug);
    }
  };

  const onMouseLeave = () => {
    if (childLinks && isMegaMenuItem) {
      setIsVisible(false);
      setIsDirty(true);
    }

    if (onMouseLeaveFromParent) {
      onMouseLeaveFromParent();
    }
  };

  return (
    <>
      <MenuItem
        {...props}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classNames(`${ROOT_CLASSNAME}`, className, {
          [`${ROOT_CLASSNAME}--is-sub-nav-visible`]:
            isVisible || (isFirstItem && !isDirty),
        })}
        linkClassName={`${ROOT_CLASSNAME}__link`}
      />
    </>
  );
};
