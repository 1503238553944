import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import FooterAccordion from '@jpp/molecules/Footer/FooterAccordion';
import SocialMenu from '@jpp/molecules/SocialMenu';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';

import './FooterTop.scss';

interface IFooterTopProps {
  className?: string;
  companySlogan: string;
  address: string;
  generalEmail: string;
  company_number: string;
  footer_heading_one: string;
  footer_heading_two: string;
  footerMenuOne: Core.IMenuItem[];
  footerMenuTwo: Core.IMenuItem[];
}

const ROOT_CLASSNAME = 'FooterTop';

export const FooterTop: FunctionComponent<IFooterTopProps> = ({
  className,
  generalEmail,
  company_number,
  address,
  footer_heading_one,
  footer_heading_two,
  footerMenuOne,
  footerMenuTwo,
}) => {
  return (
    <Section
      className={classNames(className, ROOT_CLASSNAME)}
      tagElement="div"
      theme="brand"
      withPadding={false}
    >
      <Container>
        <Row>
          <Flex colLg={4} colMd={12}>
            <SocialMenu className={`${ROOT_CLASSNAME}__social`} />
          </Flex>

          <Flex colLg={2} colMd={3} col={12} className="ml-md-auto">
            <FooterAccordion
              className={`${ROOT_CLASSNAME}__accordion`}
              heading={footer_heading_one}
              menu={footerMenuOne}
            />
          </Flex>

          <Flex colLg={2} colMd={3} col={12}>
            <FooterAccordion
              className={`${ROOT_CLASSNAME}__accordion`}
              heading={footer_heading_two}
              menu={footerMenuTwo}
            />
          </Flex>

          <Flex colLg={3} colMd={3} col={12} className="mr-md-auto mr-lg-0">
            <FooterAccordion
              className={`${ROOT_CLASSNAME}__accordion`}
              heading="Contact"
              content={
                <div className="PrimaryFooter__content">
                  <p className={`${ROOT_CLASSNAME}__copy`}>{company_number}</p>
                  <p className={`${ROOT_CLASSNAME}__copy`}>{generalEmail}</p>
                  <address
                    className={`${ROOT_CLASSNAME}__copy`}
                    dangerouslySetInnerHTML={{ __html: address }}
                  />
                </div>
              }
            />
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};
