import classNames from 'classnames';
import React from 'react';

import MyAccountDetailsForm from '@jpp/organisms/MyAccount/Forms/Details';
import { TTabGroupChildProps } from '@jpp/organisms/Tab/Group/TabGroup';

import './Details.scss';

const ROOT_CLASSNAME = 'Details';

const Details: React.FunctionComponent<TTabGroupChildProps> = ({
  className,
  parentClassName,
}) => {
  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${parentClassName}__panel`
      )}
    >
      <h1 className={`${parentClassName}__panel-heading`}>Details</h1>
      <MyAccountDetailsForm />
    </div>
  );
};

export default Details;
