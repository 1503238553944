import * as Sentry from '@sentry/node';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useIsMounted from '@jpp/hooks/useIsMounted';
import useQueryParam from '@jpp/hooks/useQueryParam';
import { TTabGroupChildProps } from '@jpp/organisms/Tab/Group/TabGroup';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { getAuth } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import axios from 'common/utils/axios/internal';
import {
  defaultCustomerSubscriptionState,
  IAPICustomerSubscriptions,
  TCustomerSubscription,
} from 'common/utils/transformers/subscription.transformer';

import AllSubscriptions, {
  TAllSubscriptions,
} from './components/AllSubscriptions';
import ViewSubscription from './components/ViewSubscription';
import './Subscriptions.scss';

const ROOT_CLASSNAME = 'Subscriptions';

const Subscriptions: React.FunctionComponent<TTabGroupChildProps> = ({
  className,
  parentClassName,
}) => {
  const router = useRouter();
  const isMounted = useIsMounted();
  const subscriptionId = useQueryParam('id');
  const user = useSelector<IReduxState, IAuthStoreState>((state) =>
    getAuth(state)
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [allSubscriptionData, setSubscriptionData] =
    React.useState<TAllSubscriptions>({
      ...defaultCustomerSubscriptionState,
      hasFetched: false,
      isLoading,
    });
  const [subscription, setSubscription] = React.useState<
    TCustomerSubscription | undefined
  >({} as TCustomerSubscription);

  const getSubscriptionData = async () => {
    if (user?.id) {
      setIsLoading(true);
      try {
        const page = router.query.page || allSubscriptionData.currentPage || 1;
        const per_page = router.query.per_page || 10;
        const { data } = await axios.get<IAPICustomerSubscriptions>(
          `/subscriptions/${user.id}/${page}/${per_page}`
        );

        if (isMounted.current) {
          setIsLoading(false);
          setSubscriptionData({
            ...data,
            hasFetched: true,
            isLoading: false,
          });
          if (subscriptionId) {
            setSubscription(
              data.subscriptions.find((o) => o.number === subscriptionId)
            );
          }
        }
      } catch (e) {
        setIsLoading(false);
        Sentry.captureException(e);
      }
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getSubscriptionData();
    }
  }, [router.query.page]);

  useEffect(() => {
    if (allSubscriptionData && isMounted.current) {
      setSubscription(
        allSubscriptionData.subscriptions.find(
          (o) => o.number === subscriptionId
        )
      );
      setIsLoading(false);
    }
  }, [subscriptionId]);

  if (!allSubscriptionData) {
    return null;
  }

  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${parentClassName}__panel`
      )}
    >
      <div className={`${parentClassName}__panel-group`}>
        {subscriptionId ? (
          <ViewSubscription
            subscriptionId={subscriptionId}
            currentPage={allSubscriptionData.currentPage}
            subscription={subscription}
            isLoading={isLoading}
          />
        ) : (
          <AllSubscriptions
            {...allSubscriptionData}
            onReFetchSubscriptions={getSubscriptionData}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
