import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import './Hamburger.scss';

interface HamburgerInterface {
  isMenuOpen?: boolean;
  className?: string;
  descriptor?: string;
  theme?: Core.TTheme;
  onClick: Core.TOnClick;
  reverse?: boolean;
  alt?: boolean;
}

const ROOT_CLASSNAME = 'Hamburger';

export const Hamburger: FunctionComponent<HamburgerInterface> = ({
  descriptor = 'Menu',
  className,
  theme = 'tint-alpha',
  reverse = false,
  isMenuOpen,
  onClick,
  alt = false,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        ROOT_CLASSNAME,
        {
          [`${ROOT_CLASSNAME}--is-active`]: isMenuOpen,
          [`${ROOT_CLASSNAME}--reverse`]: reverse,
          [`${ROOT_CLASSNAME}--alt`]: alt,
        },
        className
      )}
      onClick={onClick}
    >
      {[0, 1, 2].map((line) => (
        <span
          key={line}
          className={classNames(
            [`${ROOT_CLASSNAME}__line`],
            [`${ROOT_CLASSNAME}__line-theme--${theme}`]
          )}
        />
      ))}
      <span className={`${ROOT_CLASSNAME}__descriptor`}>{descriptor}</span>
    </button>
  );
};
