import { IOrderCouponLine } from 'common/redux/wooCommerce/orders/reducer';

export type TCustomerOrder = Omit<
  IWooCommerceAPIOrder,
  | 'parent_id'
  | 'order_key'
  | 'created_via'
  | 'version'
  | 'date_completed_gmt'
  | 'date_created_gmt'
  | 'date_modified_gmt'
  | 'date_paid_gmt'
  | 'customer_ip_address'
  | 'customer_user_agent'
  | 'transaction_id'
  | 'cart_hash'
  | 'meta_data'
  | '_links'
>;

export interface IAPICustomerOrders {
  orders: TCustomerOrder[];
  currentPage: string;
  perPage: string;
  total: string;
  totalPages: string;
}

export interface IWooCommerceAPIBilling {
  first_name: string;
  last_name: string;
  company?: string;
  address_1: string;
  address_2?: string;
  city: string;
  state?: string;
  postcode: string;
  country: string;
  email: string;
  phone: string;
}

export interface IWooCommerceAPIShipping {
  first_name: string;
  last_name: string;
  company?: string;
  address_1: string;
  address_2?: string;
  city: string;
  state?: string;
  postcode: string;
  country: string;
}

export interface IWooCommerceAPILineItem {
  id: number;
  name: string;
  product_id: number;
  variation_id: number;
  quantity: number;
  tax_class: string;
  subtotal: string;
  subtotal_tax: string;
  total: string;
  total_tax: string;
  taxes: any[];
  meta_data: any[];
  sku: string;
  price: number;
}

export interface IWooCommerceAPIShippingLine {
  id: number;
  method_title: string;
  method_id: string;
  instance_id: string;
  total: string;
  total_tax: string;
  taxes: any[];
  meta_data: any[];
}

export interface IWooCommerceAPIOrder {
  id: number;
  parent_id: number;
  number: string;
  order_key: string;
  created_via: string;
  version: string;
  status: string;
  currency: string;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  discount_total: string;
  discount_tax: string;
  shipping_total: string;
  shipping_tax: string;
  cart_tax: string;
  total: string;
  total_tax: string;
  prices_include_tax: boolean;
  customer_id: number;
  customer_ip_address: string;
  customer_user_agent: string;
  customer_note: string;
  billing: IWooCommerceAPIBilling;
  shipping: IWooCommerceAPIShipping;
  payment_method: string;
  payment_method_title: string;
  transaction_id: string;
  date_paid: any;
  date_paid_gmt: any;
  date_completed: any;
  date_completed_gmt: any;
  cart_hash: string;
  meta_data: any[];
  line_items: IWooCommerceAPILineItem[];
  tax_lines: IWooCommerceAPITaxLine[];
  shipping_lines: IWooCommerceAPIShippingLine[];
  fee_lines: any[];
  coupon_lines: IOrderCouponLine[];
  refunds: any[];
  currency_symbol: string;
  _links: any;
}

interface IWooCommerceAPITaxLine {
  id: number;
  rate_code: string;
  rate_id: number;
  label: string;
  compound: boolean;
  tax_total: string;
  shipping_tax_total: string;
}

export const orderTransformer = (
  orders: IWooCommerceAPIOrder[] = []
): TCustomerOrder[] =>
  orders.map(
    ({
      parent_id,
      order_key,
      created_via,
      version,
      date_completed_gmt,
      date_created_gmt,
      date_modified_gmt,
      date_paid_gmt,
      customer_ip_address,
      customer_user_agent,
      transaction_id,
      cart_hash,
      meta_data,
      _links,
      ...order
    }) => order
  );

export const defaultCustomerOrderState: IAPICustomerOrders = {
  orders: [],
  currentPage: '1',
  perPage: '10',
  total: '0',
  totalPages: '1',
};
