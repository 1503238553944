import classNames from 'classnames';
import React from 'react';

import { SubNavMenuItem } from '@jpp/atoms/SubNavMenuItem/SubNavMenuItem';
import MegaMenuEventCard from '@jpp/molecules/MegaMenu/EventCard';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import { IEventStoreState } from 'common/redux/event/reducer';
import { EPageType } from 'common/typings/enums';
import { MAX_SUB_NAV_PRODUCT_ITEMS } from 'common/utils/shared/product';

import './LinksWithEvents.scss';

export interface ILinksWithEventsProps {
  className?: string;
  isMegaMenuItem?: boolean;
  onClick?: Core.TOnClick;
  parentSlug?: string;
  childLinks: Core.IMenuItem[];
}

export interface IStoreLinksWithEventsProps {
  events: IEventStoreState[];
}

const ROOT_CLASSNAME = 'SubNavigation';

type TLinksWithEvents = ILinksWithEventsProps & IStoreLinksWithEventsProps;

export class LinksWithEvents extends React.PureComponent<TLinksWithEvents> {
  static defaultProps = {
    isMegaMenuItem: false,
  };

  render() {
    const { className, childLinks, onClick, isMegaMenuItem, events } =
      this.props;

    return (
      <section
        className={classNames(
          className,
          ROOT_CLASSNAME,
          `${ROOT_CLASSNAME}--links-with-events`
        )}
      >
        <Container>
          <Row>
            <Flex colLg={5} colMd={6} className={`${ROOT_CLASSNAME}__menu`}>
              {childLinks && (
                <>
                  <ul className={`${ROOT_CLASSNAME}__list`}>
                    {childLinks.map((childMenuItem, index) => (
                      <SubNavMenuItem
                        {...childMenuItem}
                        key={childMenuItem.ID}
                        isMegaMenuItem={isMegaMenuItem}
                        className={classNames(`${ROOT_CLASSNAME}__item`, {
                          [`${ROOT_CLASSNAME}__item--is-mega-menu-item`]:
                            isMegaMenuItem,
                        })}
                        onClick={onClick}
                        linkClassName={`${ROOT_CLASSNAME}__link`}
                        pageType={EPageType.Page}
                      />
                    ))}
                  </ul>
                </>
              )}
            </Flex>

            {events && (
              <Flex
                colLg={7}
                colMd={6}
                className={`${ROOT_CLASSNAME}__latest-events`}
              >
                <Row>
                  {events.map((event, index) => {
                    if (index + 1 > MAX_SUB_NAV_PRODUCT_ITEMS) {
                      return null;
                    }

                    return (
                      <Flex key={`${event.id} ${index}`} colLg={4} colMd={4}>
                        <MegaMenuEventCard
                          className={`${ROOT_CLASSNAME}__event-card`}
                          event={event}
                        />
                      </Flex>
                    );
                  })}
                </Row>
              </Flex>
            )}
          </Row>
        </Container>
      </section>
    );
  }
}
