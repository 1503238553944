import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import './Fieldset.scss';

interface IFieldsetProps {
  className?: string;
  ariaLabel?: string;
}

type TFieldset = IFieldsetProps;

const ROOT_CLASSNAME = 'Fieldset';

export const Fieldset: FunctionComponent<TFieldset> = ({
  className,
  children,
  ariaLabel,
}) => {
  return (
    <fieldset
      aria-label={ariaLabel}
      className={classNames(className, ROOT_CLASSNAME)}
    >
      {children}
    </fieldset>
  );
};

export default Fieldset;
