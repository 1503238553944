import classNames from 'classnames';
import React from 'react';

import AnchorLink, { IAnchorLinkProps } from '@jpp/atoms/AnchorLink/AnchorLink';
import SubNavMenuItem from '@jpp/atoms/SubNavMenuItem';
import { EPageType } from 'common/typings/enums';

import './MenuItem.scss';

export interface IMenuItemProps {
  className?: string;
  linkClassName?: string;
  onClick?: Core.TOnClick;
  isMegaMenuItem?: boolean;
  pageType?: EPageType;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export enum EIconPosition {
  Left = 'left',
  Right = 'right',
}

export type TMenuItem = IMenuItemProps & Core.IMenuItem;

export class MenuItem extends React.PureComponent<TMenuItem> {
  static defaultProps: Pick<TMenuItem, 'pageType' | 'isMegaMenuItem'> = {
    pageType: EPageType.Page,
    isMegaMenuItem: false,
  };

  get content() {
    return (
      <div className={classNames('MenuItem__content')}>
        <span className="MenuItem__text">{this.props.title}</span>
      </div>
    );
  }

  get element() {
    const {
      classes,
      url,
      pageType,
      slug,
      onClick,
      linkClassName,
      childLinks,
      isMegaMenuItem,
    } = this.props;
    const hasChildLinks = childLinks && childLinks.length > 0;
    const isExternal = classes && classes.includes('external');

    const elClasses = classNames('MenuItem__link', classes, linkClassName, {
      'MenuItem__link--has-child-links': hasChildLinks,
      'MenuItem__link--is-external': isExternal,
    });
    const anchorProps = {
      className: elClasses,
      href: slug,
      pageType,
      onClick,
      withStyles: false,
    } as IAnchorLinkProps;

    if (isExternal) {
      anchorProps.target = '_blank';
      anchorProps.rel = 'noopener noreferrer';
      anchorProps.href = url;
      anchorProps.behaviour = 'anchor';
    }

    return (
      <>
        <AnchorLink {...anchorProps}>{this.content}</AnchorLink>

        {childLinks && childLinks.length > 0 && (
          <ul className="MenuItem__sub-nav-list">
            {childLinks.map((link) => (
              <SubNavMenuItem
                {...link}
                key={link.ID}
                className={classNames('MenuItem__sub-nav-item', {
                  'MenuItem__sub-nav-item--is-mega-menu-item': isMegaMenuItem,
                })}
                pageType={EPageType.Page}
                linkClassName="MenuItem__sub-nav-item-link"
              />
            ))}
          </ul>
        )}
      </>
    );
  }

  render() {
    const { className, title, classes, onClick, onMouseEnter, onMouseLeave } =
      this.props;

    if (title === 'divider') {
      return null;
    }

    return (
      <li
        role={
          classes && !classes.includes('external') && onClick
            ? 'button'
            : undefined
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={classes && !classes.includes('external') ? onClick : undefined}
        className={classNames('MenuItem', className)}
      >
        {this.element}
      </li>
    );
  }
}
