export enum EEventKey {
  Space = ' ',
  Enter = 'Enter',
  Tab = 'Tab',
  Shift = 'Shift',
  Escape = 'Escape',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}
