import classNames from 'classnames';
import React from 'react';

interface IPrimaryMainProps {
  className?: string;
}

const PrimaryMain: React.FunctionComponent<IPrimaryMainProps> = ({
  className,
  children,
}) => <main className={classNames(className)} children={children} />;

export default PrimaryMain;
