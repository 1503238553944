import classNames from 'classnames';
import React from 'react';

import { getTabButtonId, getTabPanelId } from '@jpp/organisms/Tab/utils';
import { TFuncVoid } from 'common/typings/types';

import './TabButton.scss';

export interface ITabButtonProps {
  className?: string;
  activeClassName?: string;
  name: string;
  title: string;
  isSelected: boolean;
  onClick: TFuncVoid;
}

const ROOT_CLASSNAME = 'TabButton';

const TabButton: React.FunctionComponent<ITabButtonProps> = ({
  className,
  activeClassName,
  name,
  title,
  onClick,
  isSelected,
}) => {
  const tabBtnRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (isSelected) {
      tabBtnRef?.current?.focus();
    }
  }, [isSelected]);

  return (
    <li
      ref={tabBtnRef}
      id={getTabButtonId(name)}
      role="tab"
      aria-selected={isSelected}
      aria-controls={getTabPanelId(name)}
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--is-active`]: isSelected,
        [`${activeClassName}`]: isSelected && !!activeClassName,
      })}
      onClick={onClick}
      tabIndex={isSelected ? 0 : -1}
    >
      {title}
    </li>
  );
};

export default TabButton;
