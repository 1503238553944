import { connect } from 'react-redux';

import {
  IStoreTermsMenuProps,
  ITermsMenuProps,
  TermsMenu,
} from '@jpp/molecules/TermsMenu/TermsMenu';
import { getMenu } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';
import { EMenuType } from 'common/typings/enums';

const mapStateToProps = (state: IReduxState): IStoreTermsMenuProps => ({
  menu: getMenu(state, EMenuType.TermsMenu),
});

export default connect<IStoreTermsMenuProps, never, ITermsMenuProps>(
  mapStateToProps
)(TermsMenu);
