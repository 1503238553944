import classNames from 'classnames';
import React from 'react';

import { IDispatchOffCanvasProps, IStoreOffCanvasProps } from '.';
import { OffCanvasFooter } from './components/OffCanvasFooter/OffCanvasFooter';
import { OffCanvasMenu } from './components/OffCanvasMenu/OffCanvasMenu';
import './OffCanvas.scss';

export interface IOffCanvasProps {
  className?: string;
}

export type TOffCanvas = IOffCanvasProps &
  IStoreOffCanvasProps &
  IDispatchOffCanvasProps;

export const OffCanvas: React.FunctionComponent<TOffCanvas> = ({
  setMenuState,
  isMenuOpen,
  className,
  menu,
  company_slogan,
}) => {
  const handleSetMenuState = (): void => {
    setMenuState(!isMenuOpen);
  };

  return (
    <>
      <article
        className={classNames('OffCanvas', className, {
          'OffCanvas--is-active': isMenuOpen,
        })}
      >
        <div className="OffCanvas__inner">
          <OffCanvasMenu
            className="OffCanvas__menu"
            setMenuState={handleSetMenuState}
            menu={menu}
            isMenuOpen={isMenuOpen}
          />

          <OffCanvasFooter
            className="OffCanvas__footer"
            company_slogan={company_slogan}
          />
        </div>
      </article>
      <div
        role="button"
        className={classNames('OffCanvas__overlay-toggle', {
          'OffCanvas__overlay-toggle--is-active': isMenuOpen,
        })}
        onClick={handleSetMenuState}
      />
    </>
  );
};
