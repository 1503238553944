import { TOption } from 'common/typings/types';

import AmexLogo from '../../../assets/images/payment-types/american-express.png';
import ApplePayLogo from '../../../assets/images/payment-types/apple-pay.png';
import MastercardLogo from '../../../assets/images/payment-types/mastercard.png';
import VisaLogo from '../../../assets/images/payment-types/visa.png';

export enum EPaymentMethodLabel {
  Amex = 'amex',
  ApplePay = 'Apple Pay',
  Mastercard = 'mastercard',
  Visa = 'visa',
}

export const PaymentMethodLogo = {
  [EPaymentMethodLabel.Amex]: AmexLogo,
  [EPaymentMethodLabel.ApplePay]: ApplePayLogo,
  [EPaymentMethodLabel.Mastercard]: MastercardLogo,
  [EPaymentMethodLabel.Visa]: VisaLogo,
};

export enum ECountryCode {
  GB = 'United Kingdom',
  JE = 'Jersey',
  GG = 'Guernsey',
  IM = 'Isle of Man',
  US = 'United States',
  AT = 'Austria',
  BE = 'Belgium',
  BA = 'Bosnia-Herzegovina',
  BG = 'Bulgaria',
  HR = 'Croatia',
  CZ = 'Czech Republic',
  DK = 'Denmark',
  EE = 'Estonia',
  FI = 'Finland',
  FR = 'France',
  DE = 'Germany',
  GR = 'Greece',
  HU = 'Hungary',
  IS = 'Iceland',
  IT = 'Italy',
  LV = 'Latvia',
  LI = 'Liechtenstein',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  ME = 'Montenegro',
  NO = 'Norway',
  PL = 'Poland',
  PT = 'Portugal',
  IE = 'Republic Of Ireland',
  RO = 'Romania',
  RS = 'Serbia',
  SK = 'Slovakia',
  SI = 'Slovenia',
  ES = 'Spain',
  SE = 'Sweden',
  CH = 'Switzerland',
  CA = 'Canada',
  NL = 'Netherlands',
  AU = 'Australia',
  BH = 'Bahrain',
  BR = 'Brazil',
  CL = 'Chile',
  CN = "People's Republic of China",
  CO = 'Colombia',
  CR = 'Costa Rica',
  DO = 'Dominican Republic (the)',
  EC = 'Ecuador',
  EG = 'Egypt',
  HK = 'Hong Kong',
  IN = 'India',
  ID = 'Indonesia',
  IL = 'Israel',
  JP = 'Japan',
  JO = 'Jordan',
  KP = "Korea (the Democratic People's Republic of)",
  KR = 'Korea (the Republic of)',
  KW = 'Kuwait',
  LB = 'Lebanon',
  MO = 'Macao',
  MY = 'Malaysia',
  MX = 'Mexico',
  NZ = 'New Zealand',
  PK = 'Pakistan',
  PE = 'Peru',
  QA = 'Qatar',
  RU = 'Russian Federation (the)',
  SA = 'Saudi Arabia',
  SG = 'Singapore',
  ZA = 'South Africa',
  TW = 'Taiwan (Province of China)',
  TH = 'Thailand',
  UA = 'Ukraine',
  AE = 'United Arab Emirates (the)',
  UY = 'Uruguay',
  VN = 'Viet Nam',
}

export const getCountryArray = (): TOption[] => {
  return Object.keys(ECountryCode).map((country) => {
    return { [country]: ECountryCode[country] };
  });
};

export const countriesArray = getCountryArray();

export const getShippingCountryCode = (
  shippingCountry: string
): string | undefined => {
  const countryToShipTo = countriesArray.find((country) => {
    if (!country) {
      return;
    }
    const value = Object.values(country)[0];
    return value === shippingCountry;
  });

  return countryToShipTo ? Object.keys(countryToShipTo)[0] : undefined;
};
