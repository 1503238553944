import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { MenuItem } from '@jpp/atoms/MenuItem/MenuItem';
import useIsAuthEnabled from '@jpp/hooks/useIsAuthEnabled';
import useIsLoggedIn from '@jpp/hooks/useIsLoggedIn';
import { EMyAccountTab } from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import { EAuthPage } from '@jpp/templates/Auth/Auth';
import { EPageType } from 'common/typings/enums';
import { TFuncValueVoid } from 'common/typings/types';

import './OffCanvasMenu.scss';

export interface IOffCanvasMenuProps {
  className?: string;
  setMenuState?: TFuncValueVoid;
  menu: Core.IMenuItem[];
  isMenuOpen: boolean;
}

export const OffCanvasMenu: FunctionComponent<IOffCanvasMenuProps> = ({
  className,
  menu,
  setMenuState,
}) => {
  const isLoggedIn = useIsLoggedIn();
  const isAuthEnabled = useIsAuthEnabled();

  if (!menu || !Array.isArray(menu)) {
    return null;
  }

  const MAX_ITEM_INDEX = isAuthEnabled ? 5 : -1;

  return (
    <nav className={classNames(className, 'OffCanvasMenu')}>
      <ul className="OffCanvasMenu__list">
        {menu.length > 0 &&
          menu.map((menuItem: Core.IMenuItem, index: number) => {
            if (isAuthEnabled && index > MAX_ITEM_INDEX) {
              return null;
            }

            if (index === MAX_ITEM_INDEX) {
              const authId = 1007;
              const defaultAuthProps = {
                key: `${index}_menu-item`,
                ID: authId,
                url: '/',
                onClick: setMenuState,
                className: 'OffCanvasMenu__item',
                linkClassName: 'OffCanvasMenu__link',
              };

              return isLoggedIn ? (
                <MenuItem
                  {...defaultAuthProps}
                  slug={`/${EPageType.MyAccount}/${EMyAccountTab.Details}`}
                  pageType={EPageType.MyAccount}
                  title="My Account"
                />
              ) : (
                <MenuItem
                  {...defaultAuthProps}
                  slug={`/${EPageType.Auth}/${EAuthPage.Login}`}
                  pageType={EPageType.Auth}
                  title="Login"
                />
              );
            }

            if (menuItem.title === 'divider') {
              return (
                <li
                  key={`${index}_menu-divider`}
                  className="OffCanvasMenu__divider"
                />
              );
            }

            const hasChildLinks =
              menuItem.childLinks && menuItem.childLinks.length > 0;

            return (
              <MenuItem
                {...menuItem}
                key={`${index}_menu-item`}
                pageType={menuItem.pageType as EPageType}
                onClick={setMenuState}
                className={classNames('OffCanvasMenu__item', {
                  'OffCanvasMenu__item--has-child-links': hasChildLinks,
                })}
                linkClassName="OffCanvasMenu__link"
              />
            );
          })}
      </ul>
    </nav>
  );
};
