import classNames from 'classnames';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink/AnchorLink';
import Icons from '@jpp/atoms/Icon';
import useIsMounted from '@jpp/hooks/useIsMounted';
import { GHBC_SITE_WIDE_MESSAGE } from 'common/utils/constants';

import './SiteWideMessage.scss';

export interface ISiteWideMessageProps {
  className?: string;
}

export interface IStoreSiteWideMessageProps {
  siteWideMessage: Core.IOptions['site_wide_message'];
  cartSubTotal: string;
  freeDeliveryThreshold: string;
}

type TSiteWideMessage = ISiteWideMessageProps & IStoreSiteWideMessageProps;

const ROOT_CLASSNAME = 'SiteWideMessage';

export const SiteWideMessage: React.FunctionComponent<TSiteWideMessage> = ({
  className,
  siteWideMessage,
}) => {
  const isMounted = useIsMounted();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOnClose = () => {
    window.sessionStorage.setItem(GHBC_SITE_WIDE_MESSAGE, 'false');
    setIsOpen(false);
  };

  React.useEffect(() => {
    const hasBeenDismissed = !!window?.sessionStorage?.getItem(
      GHBC_SITE_WIDE_MESSAGE
    );
    if (isMounted.current) {
      setIsOpen(!hasBeenDismissed);
    }
  }, []);

  if (!siteWideMessage || !isOpen) {
    return null;
  }

  const { content, link, external_link } = siteWideMessage;
  const copy = <p className={`${ROOT_CLASSNAME}__content`}>{content}</p>;

  return (
    <article className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
        {link ? (
          <AnchorLink
            className={`${ROOT_CLASSNAME}__link`}
            behaviour={external_link ? 'anchor' : 'router'}
            href={link}
            withStyles={false}
          >
            {copy}
          </AnchorLink>
        ) : (
          copy
        )}

        <Icons.Close
          role="button"
          className={`${ROOT_CLASSNAME}__close`}
          onClick={handleOnClose}
        />
      </div>
    </article>
  );
};
