import classNames from 'classnames';
import React from 'react';

import RefImage from '@jpp/atoms/Image';
import { SubNavMenuItem } from '@jpp/atoms/SubNavMenuItem/SubNavMenuItem';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import { IProduct } from 'common/redux/wooCommerce/products/reducer';
import { EPageType } from 'common/typings/enums';

export interface ILinksWithContentProps {
  className?: string;
  isMegaMenuItem?: boolean;
  onClick?: Core.TOnClick;
  parentSlug?: string;
  childLinks: Core.IMenuItem[];
}

export interface IStoreLinksWithContentProps {
  image: Core.IMedia;
}

interface ILinksWithContentState {
  filteredProducts: IProduct[];
}

const ROOT_CLASSNAME = 'SubNavigation';

type TLinksWithContent = ILinksWithContentProps & IStoreLinksWithContentProps;

export class LinksWithContent extends React.Component<
  TLinksWithContent,
  ILinksWithContentState
> {
  static defaultProps = {
    isMegaMenuItem: false,
  };

  state: ILinksWithContentState = {
    filteredProducts: [],
  };

  render() {
    const { className, childLinks, onClick, image, isMegaMenuItem } =
      this.props;

    return (
      <section
        className={classNames(
          className,
          ROOT_CLASSNAME,
          `${ROOT_CLASSNAME}--links-with-content`
        )}
      >
        <Container>
          <Row>
            <Flex colLg={5} colMd={6}>
              {image && (
                <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
                  <RefImage
                    className={`${ROOT_CLASSNAME}__image`}
                    image={{
                      ...image,
                      width: 350,
                      height: 350,
                    }}
                  />
                </div>
              )}
            </Flex>

            <Flex colLg={7} colMd={6} className={`${ROOT_CLASSNAME}__menu`}>
              {childLinks && (
                <>
                  <ul className={`${ROOT_CLASSNAME}__list`}>
                    {childLinks.map((childMenuItem, index) => (
                      <SubNavMenuItem
                        key={childMenuItem.ID}
                        {...childMenuItem}
                        isMegaMenuItem={isMegaMenuItem}
                        className={classNames(`${ROOT_CLASSNAME}__item`, {
                          [`${ROOT_CLASSNAME}__item--is-mega-menu-item`]:
                            isMegaMenuItem,
                        })}
                        onClick={onClick}
                        linkClassName={`${ROOT_CLASSNAME}__link`}
                        pageType={EPageType.Page}
                      />
                    ))}
                  </ul>
                </>
              )}
            </Flex>
          </Row>
        </Container>
      </section>
    );
  }
}
