import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '@jpp/atoms/Heading';
import useQueryParam from '@jpp/hooks/useQueryParam';
import Button from '@jpp/molecules/Button';
import { MY_ACCOUNT_TAB_GROUP_ID } from '@jpp/organisms/MyAccount/TabGroup/constants';
import { getMyAccountTabPanels } from '@jpp/organisms/MyAccount/TabGroup/utils';
import TabGroup from '@jpp/organisms/Tab/Group';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { getAuth } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { getCurrentUser, logout } from 'common/redux/rootActions';
import { ETheme } from 'common/typings/enums';
import { EQueryParam } from 'common/utils/shared/route';

import './MyAccountTabGroup.scss';

interface IMyAccountTabGroupProps {
  className?: string;
}

export enum EMyAccountTab {
  Orders = 'orders',
  Subscriptions = 'subscriptions',
  Dashboard = 'dashboard',
  Details = 'details',
  SavedCards = 'cards',
}

export const MY_ACCOUNT_TABS = Object.values(EMyAccountTab);

const ROOT_CLASSNAME = 'MyAccountTabGroup';

const LogoutItem = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const handleOnClick = useCallback(() => {
    dispatch(logout(true));
  }, [router]);

  return (
    <Button
      className={`${ROOT_CLASSNAME}__log-out`}
      isFull={true}
      behaviour="action"
      onClick={handleOnClick}
      theme={ETheme.TintDelta}
    >
      Logout
    </Button>
  );
};

const HeadingInfo: React.FC = () => {
  const auth = useSelector<IReduxState, IAuthStoreState>((state) =>
    getAuth(state)
  );
  return (
    <>
      <Heading className={`${ROOT_CLASSNAME}__heading`} priority={2}>
        My Account
      </Heading>
      <Heading className={`${ROOT_CLASSNAME}__sub-heading`} priority={3}>
        {`Welcome back ${auth.first_name}`}
      </Heading>
    </>
  );
};

const NavHeader: React.FC = () => {
  return (
    <>
      <div
        className={`${ROOT_CLASSNAME}__header ${ROOT_CLASSNAME}__header--desktop`}
      >
        <HeadingInfo />
      </div>

      <div
        className={`${ROOT_CLASSNAME}__header ${ROOT_CLASSNAME}__header--mobile`}
      >
        <Heading className={`${ROOT_CLASSNAME}__sub-heading`} priority={3}>
          My Account Menu
        </Heading>
      </div>
    </>
  );
};

const MyAccountTabGroup: React.FunctionComponent<IMyAccountTabGroupProps> = ({
  className,
}) => {
  const tabPanelFromQueryParam = useQueryParam<EMyAccountTab>(EQueryParam.Slug);
  const panels = getMyAccountTabPanels({
    className: `${ROOT_CLASSNAME}__panel`,
    parentClassName: ROOT_CLASSNAME,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <Section
      className={ROOT_CLASSNAME}
      withPadding={false}
      tagElement="div"
      theme={ETheme.Delta}
    >
      <Container fluid={true} className={`${ROOT_CLASSNAME}__container`}>
        <Row>
          <Flex>
            <div className={`${ROOT_CLASSNAME}__mobile-intro`}>
              <HeadingInfo />
            </div>

            <TabGroup
              ariaOrientation="vertical"
              className={classNames(`${ROOT_CLASSNAME}__group`, className)}
              groupId={MY_ACCOUNT_TAB_GROUP_ID}
              parentClassName={ROOT_CLASSNAME}
              panels={panels}
              panelWrapperClassName={`${ROOT_CLASSNAME}__panel-wrapper`}
              navClassName={`${ROOT_CLASSNAME}__nav`}
              btnClassName={`${ROOT_CLASSNAME}__btn`}
              btnActiveClassName={`${ROOT_CLASSNAME}__btn--is-active`}
              hasRouteUpdate={true}
              selectedTabPanel={tabPanelFromQueryParam || EMyAccountTab.Details}
              extraNavItem={<LogoutItem />}
              navHeader={NavHeader}
            />
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};

export default MyAccountTabGroup;
