import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import React from 'react';

import FormLabel from '@jpp/atoms/FormLabel';
import Icons from '@jpp/atoms/Icon';
import { TOption } from 'common/typings/types';

import './SelectField.scss';

interface ISelectFieldProps
  extends Partial<
    Omit<HTMLSelectElement, 'options' | 'disabled' | 'className'>
  > {
  className?: string;
  name: string;
  label: string;
  disabled?: boolean;
  selectedOption?: string;
  useLabelAsValue?: boolean;
  options: TOption[];
}

type TSelectField = ISelectFieldProps;

const ROOT_CLASSNAME = 'SelectField';

const SelectField: React.FunctionComponent<TSelectField> = ({
  className,
  name,
  label,
  options,
  useLabelAsValue = true,
  selectedOption,
  ...props
}) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <FormLabel text={label} name={name} />

      <div className={`${ROOT_CLASSNAME}__error`}>
        <ErrorMessage name={name} />
      </div>

      <div className={`${ROOT_CLASSNAME}__select-wrapper`}>
        <Field
          {...props}
          className={`${ROOT_CLASSNAME}__field`}
          component="select"
          name={name}
        >
          {options?.map((option) => {
            if (!option) {
              return;
            }
            const [[value, optionLabel]] = Object.entries(option);
            const isSelected = selectedOption === optionLabel;

            return (
              <option
                key={value}
                aria-selected={isSelected}
                value={useLabelAsValue ? optionLabel : value}
              >
                {optionLabel}
              </option>
            );
          })}
        </Field>

        <Icons.Arrow className={`${ROOT_CLASSNAME}__icon`} />
      </div>
    </div>
  );
};

export default SelectField;
