import { connect } from 'react-redux';

import {
  CoreLayout,
  ICoreProps,
  IDispatchCoreProps,
  IStoreCoreProps,
} from '@jpp/layouts/CoreLayout/CoreLayout';
import { getIsAddedToCart } from 'common/redux/wooCommerce/cart/selectors';

import withScrolling from '../../hoc/withScrolling';
import { getLoadingState, getMenuState } from '../../store/core/selectors';
import { IReduxState } from '../../store/createStore';
import { setMenuState } from '../../store/rootActions';

const mapStateToProps = (state: IReduxState): IStoreCoreProps => ({
  isMenuOpen: getMenuState(state),
  isLoading: getLoadingState(state),
  isAddedToBag: getIsAddedToCart(state),
});

const mapDispatchToProps: IDispatchCoreProps = {
  onSetMenuState: setMenuState,
};

export default connect<IStoreCoreProps, IDispatchCoreProps, ICoreProps>(
  mapStateToProps,
  mapDispatchToProps
)(withScrolling<ICoreProps>(CoreLayout));
