import { connect } from 'react-redux';

import { getMenuProducts } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';

import {
  ILinksWithProductsProps,
  IStoreLinksWithProductsProps,
  LinksWithProducts,
} from './LinksWithProducts';

const mapStateToProps = (state: IReduxState): IStoreLinksWithProductsProps => ({
  products: getMenuProducts(state),
});

export default connect<
  IStoreLinksWithProductsProps,
  never,
  ILinksWithProductsProps
>(mapStateToProps)(LinksWithProducts);
