import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useIsLoggedIn from '@jpp/hooks/useIsLoggedIn';
import Breadcrumbs from '@jpp/organisms/Breadcrumbs';
import OffCanvas from '@jpp/organisms/OffCanvas';
import PrimaryFooter from '@jpp/organisms/PrimaryFooter';
import PrimaryHeader from '@jpp/organisms/PrimaryHeader';
import PrimaryMain from '@jpp/organisms/PrimaryMain';
import SiteWideMessage from '@jpp/organisms/SiteWideMessage';
import Meta from '@jpp/shared/Meta';
import { getCurrentUser } from 'common/redux/auth/actions';
import { EPageType } from 'common/typings/enums';
import { TFuncValueVoid } from 'common/typings/types';
import { HEADER_SCROLLING_THRESHOLD } from 'common/utils/constants';
import { pageVariants } from 'common/utils/shared';

import { IWithScrollingProps } from '../../hoc/withScrolling';
import './CoreLayout.scss';

export interface ICoreProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

export interface IStoreCoreProps {
  isMenuOpen: boolean;
  isLoading: boolean;
  isAddedToBag: boolean;
}

export interface IDispatchCoreProps {
  onSetMenuState: TFuncValueVoid<boolean>;
}

type TCore = ICoreProps &
  IStoreCoreProps &
  IDispatchCoreProps &
  IWithScrollingProps;

const ROOT_CLASSNAME = 'CoreLayout';

export const CoreLayout: React.FunctionComponent<TCore> = ({
  children,
  isScrollingUp,
  scroll,
  isScrolling,
  isAddedToBag,
  title,
  description,
}) => {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();
  const hasScrolled = isScrolling && scroll > HEADER_SCROLLING_THRESHOLD;
  const pagesToNotPeep: string[] = [
    EPageType.OrderCanceled,
    EPageType.OrderConfirmation,
    EPageType.Checkout,
    EPageType.Cart,
  ];
  const isOnRestrictedPage = pagesToNotPeep.some((page) =>
    router?.asPath.includes(page)
  );
  const shouldShowPeepHeader =
    (isScrollingUp && scroll > HEADER_SCROLLING_THRESHOLD) ||
    (isAddedToBag && !isOnRestrictedPage);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUser());
    }
  }, [router.pathname]);

  return (
    <div className={ROOT_CLASSNAME}>
      <Meta title={title} description={description} />

      <OffCanvas className={`${ROOT_CLASSNAME}__off-canvas`} />

      <SiteWideMessage className={`${ROOT_CLASSNAME}__site-wide-message`} />

      <div
        className={classNames(`${ROOT_CLASSNAME}__scroll-header`, {
          [`${ROOT_CLASSNAME}__scroll-header--is-active`]: shouldShowPeepHeader,
          [`${ROOT_CLASSNAME}__scroll-header--has-scrolled`]: hasScrolled,
        })}
      >
        <PrimaryHeader className={`${ROOT_CLASSNAME}__header`} />
      </div>

      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={pageVariants}
      >
        <PrimaryHeader
          className={classNames(`${ROOT_CLASSNAME}__primary-header`, {
            [`${ROOT_CLASSNAME}__primary-header--page-scrolled`]: hasScrolled,
          })}
        />

        <PrimaryMain className={`${ROOT_CLASSNAME}__main`}>
          {children}
          <Breadcrumbs className={`${ROOT_CLASSNAME}__breadcrumbs`} />
        </PrimaryMain>

        <PrimaryFooter className={`${ROOT_CLASSNAME}__footer`} />
      </motion.div>
    </div>
  );
};
