import { connect } from 'react-redux';

import { IOffCanvasProps, OffCanvas } from '@jpp/organisms/OffCanvas/OffCanvas';
import { getMenu, getMenuState, getOptions } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';
import { setMenuState } from 'common/redux/rootActions';
import { EMenuType } from 'common/typings/enums';
import { TFuncValueVoid } from 'common/typings/types';

export interface IStoreOffCanvasProps {
  isMenuOpen: boolean;
  menu: Core.IMenuItem[];
  company_slogan: string;
}

export interface IDispatchOffCanvasProps {
  setMenuState: TFuncValueVoid<boolean>;
}

const mapStateToProps = (state: IReduxState): IStoreOffCanvasProps => ({
  isMenuOpen: getMenuState(state),
  menu: getMenu(state, EMenuType.MobileMenu),
  company_slogan: getOptions(state).company_slogan || '',
});

const mapDispatchToProps: IDispatchOffCanvasProps = {
  setMenuState,
};

export default connect<
  IStoreOffCanvasProps,
  IDispatchOffCanvasProps,
  IOffCanvasProps
>(
  mapStateToProps,
  mapDispatchToProps
)(OffCanvas);
