import {
  ICartLineItem,
  ICartTotals,
} from 'common/redux/wooCommerce/cart/reducer';
import { STRIPE_MULTIPLIER } from 'common/utils/constants/stripe';
import { ISubmitOrderShippingLine } from 'common/utils/shared/orders';
import {
  getPriceWithoutCurrency,
  getSubTotal,
} from 'common/utils/shared/product';

export interface IStripeLineItem {
  name: string;
  description?: string;
  images?: string[];
  amount: number;
  currency: 'gbp';
  quantity: number;
  sku?: string;
}

export const stripeCheckoutCartLineItem = (
  items: ICartLineItem[],
  shippingMethod?: ISubmitOrderShippingLine
): IStripeLineItem[] => {
  const cartLineItems: IStripeLineItem[] = [];
  const currency = 'gbp';

  items.forEach(
    ({
      line_total,
      line_tax,
      quantity,
      sku,
      product_name,
      product_image,
      product_title,
    }) => {
      const lineAmount = line_total + line_tax;
      const parsedLineAmount = parseFloat(String(lineAmount));
      const amount = parseFloat(
        ((parsedLineAmount * STRIPE_MULTIPLIER) / quantity).toFixed(0)
      );
      return cartLineItems.push({
        name: product_name,
        images: [product_image],
        description: `${product_title}`,
        amount,
        quantity,
        currency,
        sku,
      });
    }
  );

  if (shippingMethod) {
    const total = shippingMethod.total;
    const amount = parseFloat(total) * STRIPE_MULTIPLIER;
    if (amount > 0) {
      cartLineItems.push({
        name: shippingMethod.method_title,
        quantity: 1,
        amount,
        currency,
      });
    }
  }

  return cartLineItems;
};

export const stripeCartLineItem = (
  items: ICartLineItem[],
  shippingMethod?: ISubmitOrderShippingLine
): IStripeLineItem[] => {
  const cartLineItems: IStripeLineItem[] = [];
  const currency = 'gbp';

  items.forEach(
    ({
      line_total,
      line_tax,
      quantity,
      sku,
      product_name,
      product_image,
      product_title,
    }) => {
      const lineAmount = line_total + line_tax;
      const parsedLineAmount = parseFloat(String(lineAmount));
      const amount = parseFloat(
        (parsedLineAmount * STRIPE_MULTIPLIER).toFixed(0)
      );

      return cartLineItems.push({
        name: product_name,
        images: [product_image],
        description: `${product_title}`,
        amount,
        quantity,
        currency,
        sku,
      });
    }
  );

  if (shippingMethod) {
    const total = shippingMethod.total;
    const tax = Object.values(shippingMethod.taxes)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
    let amount = parseFloat(total) + parseFloat(tax);
    amount = amount > 0 ? amount * STRIPE_MULTIPLIER : 0;

    if (amount > 0) {
      cartLineItems.push({
        name: shippingMethod.method_title,
        quantity: 1,
        amount,
        currency,
        sku: shippingMethod.method_id,
      });
    }
  }

  return cartLineItems;
};

export const getStripePrice = (price: number) =>
  parseFloat((price * STRIPE_MULTIPLIER).toFixed(0));

export const getStripePriceFromString = (price: string) =>
  getStripePrice(parseFloat(getPriceWithoutCurrency(price)));

export const ANON_TOKEN = '---';

export const paymentRequestAddressLine = (lines: string, index) =>
  lines && lines[index] ? lines[index] : ANON_TOKEN;

export const getStripeSubtotal = (totals: ICartTotals) => {
  const { subtotal_tax, discount_total, discount_tax } = totals;
  return getStripePriceFromString(
    getPriceWithoutCurrency(
      getSubTotal(totals.subtotal, subtotal_tax, discount_total, discount_tax)
    )
  );
};
