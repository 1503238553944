import { IReduxState } from 'common/redux/createStore';
import { IEventStoreState } from 'common/redux/event/reducer';

import { IEventListState, IEventsStoreState } from './reducer';

export const getEvents = (state: IReduxState): IEventsStoreState => {
  return state?.events;
};

export const getAllEvents = (state: IReduxState): IEventListState => {
  const events = getEvents(state);
  return events?.allEvents;
};

export const getAllEventsItems = (state: IReduxState): IEventStoreState[] => {
  const events = getEvents(state);
  return events?.allEvents?.events;
};

export const getFeaturedEvents = (state: IReduxState): IEventListState => {
  const events = getEvents(state);
  return events?.featuredEvents;
};

export const getFeaturedEventsItems = (
  state: IReduxState
): IEventStoreState[] => {
  const events = getEvents(state);
  return events?.featuredEvents?.events;
};
