import { format, parseISO } from 'date-fns';
import Link from 'next/link';
import React from 'react';

import ContainerLoader from '@jpp/organisms/Loader/ContainerLoader';
import { EMyAccountTab } from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import Pagination from '@jpp/organisms/Pagination';
import { EPageType } from 'common/typings/enums';
import { getPriceWithCurrency } from 'common/utils/shared/product';
import { IAPICustomerOrders } from 'common/utils/transformers/order.transformer';

const ROOT_CLASSNAME = 'Orders';

export type TAllOrders = IAPICustomerOrders & {
  hasFetched: boolean;
  isLoading: boolean;
};

const AllOrders: React.FunctionComponent<TAllOrders> = ({
  orders,
  currentPage,
  total: totalItems,
  totalPages,
  isLoading,
  hasFetched,
}) => {
  const hrefBase = `/${EPageType.MyAccount}/${EMyAccountTab.Orders}`;

  return (
    <>
      <h1 className={`${ROOT_CLASSNAME}__heading`}>
        Your Orders
        {parseInt(totalPages, 10) > 0 && (
          <span
            className={`${ROOT_CLASSNAME}__page-range`}
          >{`Page ${currentPage} of ${totalPages}`}</span>
        )}
      </h1>

      <p className={`${ROOT_CLASSNAME}__copy ${ROOT_CLASSNAME}__copy--notice`}>
        On smaller devices please scroll right to view all the information
        below.
      </p>

      {(!hasFetched || isLoading) && (
        <div className={`${ROOT_CLASSNAME}__empty`}>
          <ContainerLoader isVisible={true} />
        </div>
      )}

      {hasFetched && !isLoading && (
        <>
          {orders.length === 0 ? (
            <p className={`${ROOT_CLASSNAME}__copy`}>
              You don't appear to have any orders. If this is a mistake please
              contact support info@gipsyhillbrewing.com.
            </p>
          ) : (
            <>
              <div className={`${ROOT_CLASSNAME}__table-wrapper`}>
                <table className={`${ROOT_CLASSNAME}__table`}>
                  <thead className={`${ROOT_CLASSNAME}__thead`}>
                    <tr className={`${ROOT_CLASSNAME}__tr`}>
                      <th className={`${ROOT_CLASSNAME}__th`}>ID</th>
                      <th
                        className={`${ROOT_CLASSNAME}__th ${ROOT_CLASSNAME}__th--date`}
                      >
                        Date
                      </th>
                      <th className={`${ROOT_CLASSNAME}__th`}>Total</th>
                      <th className={`${ROOT_CLASSNAME}__th`}>Status</th>
                      <th
                        className={`${ROOT_CLASSNAME}__th ${ROOT_CLASSNAME}__th--actions`}
                      />
                    </tr>
                  </thead>

                  <tbody className={`${ROOT_CLASSNAME}__tbody`}>
                    {orders.map((order) => {
                      if (!order) {
                        return null;
                      }

                      const price = parseFloat(order.total);

                      return (
                        <tr className={`${ROOT_CLASSNAME}__tr`} key={order.id}>
                          <td className={`${ROOT_CLASSNAME}__td`}>
                            #{order.id}
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td ${ROOT_CLASSNAME}__td--date`}
                          >
                            {format(parseISO(order.date_created), 'PPP')}
                          </td>

                          <td className={`${ROOT_CLASSNAME}__td`}>
                            {price === 0 ? (
                              <strong>FREE</strong>
                            ) : (
                              getPriceWithCurrency(price)
                            )}
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td ${ROOT_CLASSNAME}__td--status ${ROOT_CLASSNAME}__td--${order.status}`}
                          >
                            <span>{order?.status?.replace(/-/g, ' ')}</span>
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td ${ROOT_CLASSNAME}__td--actions`}
                          >
                            <Link href={`${hrefBase}?id=${order.id}`}>
                              <a className={`${ROOT_CLASSNAME}__link`}>
                                View order
                              </a>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <Pagination
                currentPage={parseFloat(currentPage)}
                totalItems={parseFloat(totalItems)}
                totalPages={parseFloat(totalPages)}
                hrefBase={hrefBase}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AllOrders;
