import { connect } from 'react-redux';

import { getOptions } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';

import { MockImage } from '../../../../__mocks__/MockImage';
import {
  ILinksWithContentProps,
  IStoreLinksWithContentProps,
  LinksWithContent,
} from './LinksWithContent';

const mapStateToProps = (state: IReduxState): IStoreLinksWithContentProps => {
  const megaMenuOptions = getOptions(state).mega_menu;
  const image = megaMenuOptions && megaMenuOptions.about_image;
  const defaultImage = megaMenuOptions && megaMenuOptions.default_image;
  return {
    image: image || defaultImage || MockImage,
  };
};

export default connect<
  IStoreLinksWithContentProps,
  never,
  ILinksWithContentProps
>(mapStateToProps)(LinksWithContent);
