import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import stripeJs, { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import getDisplayName from 'common/utils/shared';

import ConfigProvider from '../services/configProvider';

const stripePromise = loadStripe(ConfigProvider.getValue('STRIPE_PUBLIC'));

export interface IWithStripeElements {
  stripe: stripeJs.Stripe;
  elements: stripeJs.StripeElements;
}

const withStripe = <
  IComponentProps extends IWithStripeElements = IWithStripeElements
>(
  Component: React.ComponentType<IWithStripeElements>
): React.ComponentType<Omit<IComponentProps, keyof IWithStripeElements>> => {
  const WithStripeElements: React.ComponentType<
    Omit<IComponentProps, keyof IWithStripeElements>
  > = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    return stripe && elements ? (
      <Component {...props} stripe={stripe} elements={elements} />
    ) : null;
  };

  WithStripeElements.displayName = getDisplayName(Component);

  const WithStripe: React.FunctionComponent<
    Omit<IComponentProps, keyof IWithStripeElements>
  > = (props) => (
    <Elements stripe={stripePromise}>
      <WithStripeElements {...props} />
    </Elements>
  );
  WithStripe.displayName = `withStripe(${getDisplayName(Component)})`;

  return WithStripe;
};

export default withStripe;
