import classNames from 'classnames';
import React from 'react';

import { TTabGroupChildProps } from '@jpp/organisms/Tab/Group/TabGroup';

import './Dashboard.scss';

const ROOT_CLASSNAME = 'Dashboard';

const Dashboard: React.FunctionComponent<TTabGroupChildProps> = ({
  className,
  parentClassName,
}) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <h1 className={`${parentClassName}__panel-heading`}>Dashboard</h1>
    </div>
  );
};

export default Dashboard;
