import classNames from 'classnames';
import React from 'react';

import { getTabButtonId, getTabPanelId } from '@jpp/organisms/Tab/utils';

import './TabPanel.scss';
import { ITabPanelProps } from './types';

const ROOT_CLASSNAME = 'TabPanel';

const TabPanel: React.FunctionComponent<ITabPanelProps> = ({
  className,
  slug,
  isSelected = false,
  children,
}) => (
  <div
    id={getTabPanelId(slug)}
    aria-labelledby={getTabButtonId(slug)}
    aria-hidden={!isSelected}
    role="tabpanel"
    className={classNames(ROOT_CLASSNAME, className, {
      [`${ROOT_CLASSNAME}--is-selected`]: isSelected,
    })}
    tabIndex={0}
  >
    {children}
  </div>
);

export default TabPanel;
