import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getOptions } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';
import {
  getCartLineItems,
  getCartTotalItems,
} from 'common/redux/wooCommerce/cart/selectors';
import {
  getCartSubtotalLessVirtual,
  hasOnlyVirtualProductsInCart,
} from 'common/utils/shared/cart';
import { getPriceWithCurrency } from 'common/utils/shared/product';

const QTY_FOR_FREE_DELIVERY = 16;

const useFreeDeliveryThreshold = () => {
  const { totalItems, cartLineItems, options } = useSelector(
    (state: IReduxState) => ({
      cartLineItems: getCartLineItems(state),
      totalItems: getCartTotalItems(state),
      options: getOptions(state),
    })
  );

  const freeDeliveryThreshold = parseFloat(
    options.free_delivery_threshold || '0'
  );
  const shouldUseCurrency = options.use_price_threshold;
  const hasOnlyVirtualProducts = useMemo(
    () => hasOnlyVirtualProductsInCart(cartLineItems),
    [cartLineItems]
  );
  const threshold = parseInt(
    `${options.free_delivery_item_qty || QTY_FOR_FREE_DELIVERY}`,
    10
  );
  const itemDiff = totalItems >= threshold;
  const freeDeliveryDiff = useMemo(
    () => getCartSubtotalLessVirtual(freeDeliveryThreshold, cartLineItems),
    [cartLineItems, freeDeliveryThreshold]
  );
  const moneyToPay = useMemo(
    () => getPriceWithCurrency(freeDeliveryDiff),
    [freeDeliveryDiff]
  );
  const isLessThanZero = freeDeliveryDiff <= 0;
  const messagingItem = itemDiff
    ? 'Congratulations you have free delivery'
    : `${threshold - totalItems} items away from free delivery`;
  const messagingCurrency = isLessThanZero
    ? 'Congratulations you have free delivery'
    : `${moneyToPay} away from free delivery`;

  return {
    hasOnlyVirtualProducts,
    messaging: shouldUseCurrency ? messagingCurrency : messagingItem,
    moneyToPay,
    isLessThanZero: shouldUseCurrency ? isLessThanZero : itemDiff,
  };
};

export default useFreeDeliveryThreshold;
