import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import './Legend.scss';

interface IFormLegendProps {
  className?: string;
}

const ROOT_CLASSNAME = 'Legend';

export const Legend: FunctionComponent<IFormLegendProps> = ({
  className,
  children,
}) => {
  return (
    <legend className={classNames(className, ROOT_CLASSNAME)}>
      {children}
    </legend>
  );
};

export default Legend;
