import classNames from 'classnames';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink/AnchorLink';
import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import { IEventStoreState } from 'common/redux/event/reducer';
import { EPageType } from 'common/typings/enums';

import './MegaMenuEventCard.scss';

interface IMegaMenuEventCardProps {
  className?: string;
  event: IEventStoreState;
}

const ROOT_CLASSNAME = 'MegaMenuEventCard';

export const MegaMenuEventCard: React.FunctionComponent<
  IMegaMenuEventCardProps
> = ({ className, event }) => {
  const { title, slug } = event;
  const image =
    event.featured_image ||
    ({
      src: event.media?.medium,
      alt: `${title} event image`,
    } as Core.IMedia);
  const imageSrc = image?.src || image?.url;
  const href = `/${EPageType.Event}/${slug}`;

  return (
    <AnchorLink className={classNames(ROOT_CLASSNAME, className)} href={href}>
      <div className={`${ROOT_CLASSNAME}__header`}>
        {image && imageSrc && (
          <RefImage
            className={`${ROOT_CLASSNAME}__image`}
            image={image}
            shouldLazyLoad={false}
          />
        )}
      </div>

      <Heading
        className={`${ROOT_CLASSNAME}__heading`}
        priority={4}
        children={title}
      />

      <span className={`${ROOT_CLASSNAME}__cta`}>Plan your visit</span>
    </AnchorLink>
  );
};
