import classNames from 'classnames';
import { FieldProps } from 'formik';
import React from 'react';

import { TFuncValueVoid } from 'common/typings/types';

import './Checkbox.scss';

interface ICheckboxProps {
  className?: string;
  name: string;
  value: string;
  label: string | JSX.Element;
  onChange: TFuncValueVoid;
}

type TCheckbox = ICheckboxProps & FieldProps;

const ROOT_CLASSNAME = 'Checkbox';

const Checkbox: React.FunctionComponent<TCheckbox> = ({
  className,
  name,
  label,
  field,
}) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <label htmlFor={name} className={`${ROOT_CLASSNAME}__label`}>
        <span
          className={classNames(`${ROOT_CLASSNAME}__checkbox`, {
            [`${ROOT_CLASSNAME}__checkbox--is-checked`]: field.value,
          })}
        />

        <span className={`${ROOT_CLASSNAME}__text`}>{label}</span>

        <input
          className={`${ROOT_CLASSNAME}__input`}
          type="checkbox"
          id={name}
          hidden={true}
          checked={field.value}
          aria-checked={field.value}
          aria-hidden={false}
          aria-label={name}
          {...field}
        />
      </label>
    </div>
  );
};

export default Checkbox;
