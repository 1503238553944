import { connect } from 'react-redux';

import { IReduxState } from 'common/redux/createStore';
import { getCartTotalItems } from 'common/redux/wooCommerce/cart/selectors';

import {
  IShoppingCartProps,
  IStoreShoppingCartProps,
  ShoppingCartIcon,
} from './ShoppingCartIcon';

const mapStateToProps = (state: IReduxState): IStoreShoppingCartProps => ({
  totalItems: getCartTotalItems(state),
});

export default connect<IStoreShoppingCartProps, never, IShoppingCartProps>(
  mapStateToProps
)(ShoppingCartIcon);
