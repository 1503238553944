import { connect } from 'react-redux';

import { getOptions, getSiteWideMessage } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';
import { getCartSubTotal } from 'common/redux/wooCommerce/cart/selectors';

import {
  ISiteWideMessageProps,
  IStoreSiteWideMessageProps,
  SiteWideMessage,
} from './SiteWideMessage';

const mapStateToProps = (state: IReduxState): IStoreSiteWideMessageProps => ({
  siteWideMessage: getSiteWideMessage(state),
  freeDeliveryThreshold: getOptions(state).free_delivery_threshold || '0',
  cartSubTotal: getCartSubTotal(state),
});

export default connect<
  IStoreSiteWideMessageProps,
  never,
  ISiteWideMessageProps
>(mapStateToProps)(SiteWideMessage);
