import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink/AnchorLink';
import Icon from '@jpp/atoms/Icon';
import { TMenuItem } from '@jpp/atoms/MenuItem/MenuItem';
import {
  LINKS_WITH_CONTENT,
  LINKS_WITH_EVENTS,
} from '@jpp/organisms/MegaMenu/constants';
import LinksWithContent from '@jpp/organisms/SubNavigation/LinksWithContent';
import LinksWithEvents from '@jpp/organisms/SubNavigation/LinksWithEvents';
import { IStoreLinksWithEventsProps } from '@jpp/organisms/SubNavigation/LinksWithEvents/LinksWithEvents';
import LinksWithProducts from '@jpp/organisms/SubNavigation/LinksWithProducts';
import { megaMenuVariants } from 'common/utils/shared';

import './MegaMenuItem.scss';

export interface IMegaMenuItemProps extends TMenuItem {
  className?: string;
  isActive?: boolean;
}

interface IMegaMenuState {
  isVisible: boolean;
}

const ROOT_CLASSNAME = 'MegaMenuItem';

export type TMegaMenuItem = IMegaMenuItemProps & IStoreLinksWithEventsProps;

export class MegaMenuItem extends React.Component<
  TMegaMenuItem,
  IMegaMenuState
> {
  state: IMegaMenuState = {
    isVisible: false,
  };

  onMouseEnter = () => {
    if (this.props.childLinks) {
      this.setState({ isVisible: true });
    }
  };

  onMouseLeave = () => {
    if (this.props.childLinks) {
      this.setState({ isVisible: false });
    }
  };

  hasEvents = () => this.props?.events?.length;

  getSubNavComponent = (): React.ComponentType<any> => {
    const classes = this.props.classes || '';

    if (classes.includes(LINKS_WITH_CONTENT)) {
      return LinksWithContent;
    }

    if (classes.includes(LINKS_WITH_EVENTS) && this.hasEvents()) {
      return LinksWithEvents;
    }

    return LinksWithProducts;
  };

  hasChildLinks = () =>
    this.props.childLinks && this.props.childLinks.length > 0;

  render() {
    const { className, slug, title, childLinks, linkClassName, classes } =
      this.props;
    const { isVisible } = this.state;
    const hasChildLinks = this.hasChildLinks();
    const SubNavComponent = this.getSubNavComponent();
    const behaviour: Core.TLinkBehaviour = classes?.includes('external')
      ? 'anchor'
      : 'router';

    return (
      <>
        <li
          className={classNames(className, ROOT_CLASSNAME)}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <AnchorLink
            href={slug}
            behaviour={behaviour}
            className={classNames(`${ROOT_CLASSNAME}__link`, linkClassName, {
              [`${ROOT_CLASSNAME}__link--is-active`]:
                hasChildLinks && isVisible,
            })}
          >
            {title}
            {hasChildLinks && (
              <Icon.Arrow
                className={classNames(`${ROOT_CLASSNAME}__icon`, {
                  [`${ROOT_CLASSNAME}__icon--is-active`]: isVisible,
                })}
                inline={true}
              />
            )}
          </AnchorLink>

          {hasChildLinks && isVisible && (
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={megaMenuVariants}
            >
              <SubNavComponent
                isMegaMenuItem={true}
                parentSlug={slug}
                onClick={this.onMouseLeave}
                className={`${ROOT_CLASSNAME}__sub-nav`}
                childLinks={childLinks}
              />
            </motion.div>
          )}
        </li>

        {hasChildLinks && (
          <div
            className={classNames(`${ROOT_CLASSNAME}__overlay`, {
              [`${ROOT_CLASSNAME}__overlay--is-active`]: isVisible,
            })}
          />
        )}
      </>
    );
  }
}

export default MegaMenuItem;
