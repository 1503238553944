import classNames from 'classnames';
import React from 'react';

import TermsMenu from '@jpp/molecules/TermsMenu';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';

import './FooterBottom.scss';

interface IFooterBottomProps {
  className?: string;
  companyName: string;
}

export const FooterBottom: React.FunctionComponent<IFooterBottomProps> = ({
  className,
  companyName,
}) => {
  const cookiePreferenceHandleClick = (
    event: React.SyntheticEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    if (window?.displayPreferenceModal) {
      window.displayPreferenceModal();
    }
  };

  return (
    <Section
      tagElement="div"
      className={classNames(className, 'FooterBottom')}
      withPadding={false}
      theme="brand"
    >
      <Container>
        <Row>
          <Flex colLg={5} className="mr-md-auto text-lg-left text-center">
            <TermsMenu className="FooterBottom__menu" />
          </Flex>

          <Flex colLg={2} className="mx-auto">
            <p className="FooterBottom__copy FooterBottom__copy--company-name">
              <span className="FooterBottom__copy-text">
                &copy; {`${companyName}`} {new Date().getFullYear()}
              </span>
            </p>
          </Flex>

          <Flex colLg={5} className="ml-md-auto">
            <p className="FooterBottom__copy FooterBottom__copy--credits">
              <span className="FooterBottom__copy-text ml-lg-auto mx-auto mr-lg-0">
                Website designed by{' '}
                <a
                  className="FooterBottom__link"
                  href="//amyshepheard.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Amy Shepheard
                </a>{' '}
                and built by{' '}
                <a
                  className="FooterBottom__link"
                  href="//www.pixeltwist.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PixelTwist
                </a>
                <br />
                Photography by{' '}
                <a
                  className="FooterBottom__link"
                  href="//www.mickhabgood.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mick Habgood
                </a>
                ,{' '}
                <a
                  className="FooterBottom__link"
                  href="//www.emmaguscott.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Emma Guscott
                </a>
                <br />
                Illustrations by{' '}
                <a
                  className="FooterBottom__link"
                  href="//www.marcusreed.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marcus Reed
                </a>
              </span>

              <a
                href="#"
                role="button"
                className="FooterBottom__link FooterBottom__link--cookie-center"
                onClick={cookiePreferenceHandleClick}
              >
                Manage Cookie Preferences
              </a>
            </p>
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};
