import classNames from 'classnames';
import React, { useMemo } from 'react';

import TabList from '@jpp/molecules/Tab/List';
import { ITabListProps } from '@jpp/molecules/Tab/List/TabList';
import TabPanel from '@jpp/molecules/Tab/Panel';
import { ITabPanelProps } from '@jpp/molecules/Tab/Panel/types';

import './TabGroup.scss';

export interface ITabGroupProps {
  className?: string;
  parentClassName: string;
  navClassName: string;
  panelWrapperClassName: string;
  groupId: string;
  navHeader?: React.ComponentType<any>;
  extraNavItem?: React.ReactNode;
  selectedTabPanel: ITabListProps['selectedPanel'];
  panels: ITabPanelProps[];
}

export type TTabGroupChildProps = Pick<
  ITabGroupProps,
  'className' | 'parentClassName'
>;

const ROOT_CLASSNAME = 'TabGroup';

export type TTabGroup = ITabGroupProps &
  Omit<ITabListProps, 'setSelectedPanel' | 'navItems' | 'selectedPanel'>;

const TabGroup: React.FunctionComponent<TTabGroup> = ({
  className,
  parentClassName,
  groupId,
  ariaOrientation,
  panels = [],
  navClassName,
  navHeader,
  panelWrapperClassName,
  btnClassName,
  btnActiveClassName,
  extraNavItem,
  hasRouteUpdate,
  selectedTabPanel,
}) => {
  const navItems = useMemo(
    () =>
      panels.map(({ slug, label, isSelectable }) => ({
        slug,
        label: label || slug,
        isSelectable,
      })),
    [panels]
  );

  const [selectedPanel, setSelectedSubNavItem] = React.useState(
    selectedTabPanel || navItems[0].slug
  );

  if (!navItems || navItems.length === 0) {
    return null;
  }

  return (
    <article id={groupId} className={classNames(ROOT_CLASSNAME, className)}>
      <TabList
        ariaOrientation={ariaOrientation}
        className={navClassName}
        navItems={navItems}
        extraNavItem={extraNavItem}
        navHeader={navHeader}
        parentClassName={parentClassName}
        btnClassName={btnClassName}
        btnActiveClassName={btnActiveClassName}
        hasRouteUpdate={hasRouteUpdate}
        selectedPanel={selectedPanel}
        setSelectedPanel={setSelectedSubNavItem}
      />

      <div className={panelWrapperClassName}>
        {panels.map(({ slug, children, isSelectable }) => {
          const isSelected = slug === selectedPanel;
          if (!isSelected) {
            return null;
          }

          return (
            <TabPanel
              key={slug}
              slug={slug}
              isSelected={isSelected}
              isSelectable={isSelectable}
              children={children}
            />
          );
        })}
      </div>
    </article>
  );
};

export default TabGroup;
