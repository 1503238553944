export const MockImage: Core.IMedia = {
  id: 1073,
  title: 'DSC01695',
  filename: 'DSC01695.jpg',
  filesize: 74852,
  url: 'https://api.gipsyhillbrew.com/wp-content/uploads/2020/03/DSC01695.jpg',
  src: 'https://api.gipsyhillbrew.com/wp-content/uploads/2020/03/DSC01695.jpg',
  link: 'https://api.gipsyhillbrew.com/our-beers/dsc01695/',
  alt: '',
  author: '1',
  description: '',
  caption: '',
  name: 'dsc01695',
  status: 'inherit',
  uploaded_to: 1065,
  date: '2020-03-22 17:07:06',
  modified: '2020-03-22 17:07:06',
  menu_order: 0,
  mime_type: 'image/jpeg',
  type: 'image',
  subtype: 'jpeg',
  icon: 'https://api.gipsyhillbrew.com/wp-includes/images/media/default.png',
  width: 1000,
  height: 667,
  sizes: {
    thumbnail:
      'https://api.gipsyhillbrew.com/wp-content/uploads/2020/03/DSC01695-150x150.jpg',
    'thumbnail-width': 150,
    'thumbnail-height': 150,
    medium:
      'https://api.gipsyhillbrew.com/wp-content/uploads/2020/03/DSC01695-300x200.jpg',
    'medium-width': 300,
    'medium-height': 200,
    medium_large:
      'https://api.gipsyhillbrew.com/wp-content/uploads/2020/03/DSC01695-768x512.jpg',
    'medium_large-width': 768,
    'medium_large-height': 512,
    large:
      'https://api.gipsyhillbrew.com/wp-content/uploads/2020/03/DSC01695.jpg',
    'large-width': 1000,
    'large-height': 667,
  },
};
