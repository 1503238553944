import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { ReactText } from 'react';

import { getValidURLQueries } from '@jpp/atoms/AnchorLink/utils';
import TabButton from '@jpp/atoms/Tab/Button';
import { TTabNavItem } from '@jpp/molecules/Tab/Panel/types';
import {
  EMyAccountTab,
  MY_ACCOUNT_TABS,
} from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import { EEventKey } from 'common/utils/accessibility';
import { getQueryParamFirstValue } from 'common/utils/shared/route';

import './TabList.scss';

export interface ITabListProps {
  title?: string;
  parentClassName?: string;
  className?: string;
  btnClassName?: string;
  btnActiveClassName?: string;
  hasRouteUpdate?: boolean;
  navHeader?: React.ComponentType<any>;
  extraNavItem?: React.ReactNode;
  ariaOrientation?: 'horizontal' | 'vertical';
  navItems: TTabNavItem[];
  selectedPanel: ReactText;
  setSelectedPanel: React.Dispatch<React.SetStateAction<ReactText>>;
}

const ROOT_CLASSNAME = 'TabList';

export type TTabList = ITabListProps;

const TabList: React.FunctionComponent<TTabList> = ({
  className,
  selectedPanel,
  navHeader: NavHeader,
  extraNavItem,
  setSelectedPanel,
  navItems = [],
  hasRouteUpdate = false,
  btnActiveClassName,
  btnClassName,
  ariaOrientation = 'horizontal',
}) => {
  const router = useRouter();
  const items = navItems.filter((item) => !!item.isSelectable);
  const query = getValidURLQueries(router.query);
  const tab = getQueryParamFirstValue<EMyAccountTab>(
    router.query.slug as EMyAccountTab
  );

  const handleTabChange = (item: string) => {
    if (hasRouteUpdate) {
      const pathname = router.pathname.replace(/\/\[(.*$)/g, '');
      router.push({
        pathname: `${pathname}/${item}`,
        query,
      });
      window.scrollTo(0, 0);
    }
    setSelectedPanel(item);
  };

  React.useEffect(() => {
    if (MY_ACCOUNT_TABS.includes(tab as EMyAccountTab)) {
      setSelectedPanel(tab);
    }
  }, [tab]);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    const key = event.key;
    const currentPanelIndex = items.findIndex(
      (navPanel) => navPanel.slug === selectedPanel
    );
    const lastPanel = items.length - 1;
    const isEnd = currentPanelIndex === lastPanel;
    const isBeginning = currentPanelIndex === 0;

    if (
      (ariaOrientation === 'horizontal' && key === EEventKey.ArrowRight) ||
      (ariaOrientation === 'vertical' && key === EEventKey.ArrowDown)
    ) {
      event.preventDefault();
      const nextPanelIndex = isEnd ? 0 : currentPanelIndex + 1;
      const nextNavItem = items[nextPanelIndex];
      handleTabChange(nextNavItem.slug);
    }

    if (
      (ariaOrientation === 'horizontal' && key === EEventKey.ArrowLeft) ||
      (ariaOrientation === 'vertical' && key === EEventKey.ArrowUp)
    ) {
      event.preventDefault();
      const prevPanelIndex = isBeginning ? lastPanel : currentPanelIndex - 1;
      const prevNavItem = items[prevPanelIndex];
      handleTabChange(prevNavItem.slug);
    }
  };

  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      {NavHeader && (
        <div className={`${ROOT_CLASSNAME}__header`}>
          <NavHeader />
        </div>
      )}
      <ul
        role="tablist"
        aria-orientation={ariaOrientation}
        className={`${ROOT_CLASSNAME}__list`}
        onKeyDown={handleOnKeyDown}
      >
        {items.map(({ slug, label, isSelectable = true }) => {
          if (!isSelectable) {
            return null;
          }
          return (
            <TabButton
              key={slug}
              className={classNames(`${ROOT_CLASSNAME}__item`, btnClassName)}
              activeClassName={btnActiveClassName}
              name={slug}
              title={label || slug.replace(/-/g, ' ')}
              isSelected={selectedPanel === slug}
              onClick={() => handleTabChange(slug)}
            />
          );
        })}
        {extraNavItem}
      </ul>
    </div>
  );
};

export default TabList;
