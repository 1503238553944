import { useRouter } from 'next/router';

import { getQueryParamFirstValue } from 'common/utils/shared/route';

const useQueryParam = <T extends string = string>(
  key: string
): T | undefined => {
  const router = useRouter();
  return getQueryParamFirstValue<T, T>(router.query[key] as T);
};

export default useQueryParam;
