import classNames from 'classnames';
import React from 'react';

import { SubNavMenuItem } from '@jpp/atoms/SubNavMenuItem/SubNavMenuItem';
import MegaMenuProductCard from '@jpp/molecules/MegaMenu/ProductCard';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import { IPdpProduct } from 'common/redux/pdp/reducer';
import { EPageType, EStatus } from 'common/typings/enums';
import { EProductStock } from 'common/typings/enums/product';
import { MAX_SUB_NAV_PRODUCT_ITEMS } from 'common/utils/shared/product';

import './LinksWithProducts.scss';

export interface ILinksWithProductsProps {
  className?: string;
  isMegaMenuItem?: boolean;
  onClick?: Core.TOnClick;
  parentSlug?: string;
  childLinks: Core.IMenuItem[];
}

export interface IStoreLinksWithProductsProps {
  products: IPdpProduct[];
}

const ROOT_CLASSNAME = 'SubNavigation';

type TLinksWithProducts = ILinksWithProductsProps &
  IStoreLinksWithProductsProps;

export class LinksWithProducts extends React.PureComponent<TLinksWithProducts> {
  static defaultProps = {
    isMegaMenuItem: false,
  };

  render() {
    const { className, childLinks, onClick, isMegaMenuItem, products } =
      this.props;

    const inStockProducts = products?.filter((product) => {
      return (
        product.stock_status === EProductStock.InStock &&
        product.post_status === EStatus.Publish
      );
    });

    return (
      <section
        className={classNames(
          className,
          ROOT_CLASSNAME,
          `${ROOT_CLASSNAME}--links-with-products`
        )}
      >
        <Container>
          <Row>
            <Flex colLg={5} colMd={6} className={`${ROOT_CLASSNAME}__menu`}>
              {childLinks && (
                <>
                  <ul className={`${ROOT_CLASSNAME}__list`}>
                    {childLinks.map((childMenuItem, index) => (
                      <SubNavMenuItem
                        key={childMenuItem.ID}
                        {...childMenuItem}
                        isFirstItem={index === 0}
                        isMegaMenuItem={isMegaMenuItem}
                        className={classNames(`${ROOT_CLASSNAME}__item`, {
                          [`${ROOT_CLASSNAME}__item--is-mega-menu-item`]:
                            isMegaMenuItem,
                        })}
                        onClick={onClick}
                        linkClassName={`${ROOT_CLASSNAME}__link`}
                        pageType={EPageType.Page}
                      />
                    ))}
                  </ul>
                </>
              )}
            </Flex>

            {inStockProducts && (
              <Flex
                colLg={7}
                colMd={6}
                className={`${ROOT_CLASSNAME}__latest-products`}
              >
                <Row>
                  {inStockProducts.map((product, index) => {
                    if (index + 1 > MAX_SUB_NAV_PRODUCT_ITEMS) {
                      return null;
                    }

                    return (
                      <Flex key={`${product.id} ${index}`} colLg={4} colMd={4}>
                        <MegaMenuProductCard
                          className={`${ROOT_CLASSNAME}__product-card`}
                          product={product}
                        />
                      </Flex>
                    );
                  })}
                </Row>
              </Flex>
            )}
          </Row>
        </Container>
      </section>
    );
  }
}
