import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import OrderProductCard from '@jpp/molecules/OrderProductCard';
import { EMyAccountTab } from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import { EPageType, ESubscriptionStatus } from 'common/typings/enums';
import { GIFT_CARD_PREFIX, SITE_TITLE } from 'common/utils/constants';
import { getPriceWithCurrency } from 'common/utils/shared/product';
import { TCustomerSubscription } from 'common/utils/transformers/subscription.transformer';

import './ViewSubscription.scss';

interface IViewSubscriptionProps {
  className?: string;
  subscriptionId: string;
  currentPage: string;
  subscription?: TCustomerSubscription;
  isLoading: boolean;
}

const ROOT_CLASSNAME = 'ViewSubscription';

const ViewSubscription: React.FunctionComponent<IViewSubscriptionProps> = ({
  className,
  subscriptionId,
  currentPage = '1',
  isLoading,
  subscription = {} as TCustomerSubscription,
}) => {
  if (!subscriptionId) {
    return null;
  }

  const {
    total,
    total_tax,
    shipping_total,
    shipping_tax,
    discount_tax,
    discount_total,
    status,
  } = subscription;
  const products = subscription?.line_items;
  const nonShippableProducts =
    products?.filter((item) => item.sku.includes(GIFT_CARD_PREFIX)) || [];
  const hasOnlyNonShippableProducts =
    nonShippableProducts.length === products?.length;
  const shippingTotal =
    shipping_total && shipping_tax
      ? parseFloat(shipping_total) + parseFloat(shipping_tax)
      : 0;
  const taxTotal =
    total_tax && shipping_tax
      ? parseFloat(total_tax) - parseFloat(shipping_tax)
      : 0;
  const discountTotal =
    discount_total && discount_tax
      ? parseFloat(discount_total) + parseFloat(discount_tax)
      : 0;
  const subtotal =
    total && total_tax
      ? parseFloat(total) -
        parseFloat(total_tax) -
        parseFloat(shipping_total) +
        discountTotal
      : 0;
  const totalItems = products
    ?.map((product) => product.quantity)
    .reduce((a, b) => a + b, 0);
  const isExpiredOrCancelled =
    status === ESubscriptionStatus.Expired ||
    status === ESubscriptionStatus.Cancelled;

  return (
    <>
      <Head>
        <title>
          Subscriptions #{subscriptionId} | My Account | {SITE_TITLE}
        </title>
      </Head>

      <div className={classNames(className, ROOT_CLASSNAME)}>
        <Link
          href={`/${EPageType.MyAccount}/${EMyAccountTab.Subscriptions}/?page=${currentPage}`}
        >
          <a className={`${ROOT_CLASSNAME}__link`}>
            {'< back to All Subscriptions'}
          </a>
        </Link>

        <h1 className={`${ROOT_CLASSNAME}__heading`}>
          Subscription #{subscriptionId}
          {status && (
            <span
              className={`${ROOT_CLASSNAME}__status ${ROOT_CLASSNAME}__status--${subscription.status}`}
            >
              {status.replace(/-/g, ' ')}
            </span>
          )}
        </h1>

        {subscription &&
          Object.keys(subscription).length === 0 &&
          !isLoading && (
            <p className={`${ROOT_CLASSNAME}__copy mt-1`}>
              Fetching your subscription details...
            </p>
          )}

        {subscription && Object.keys(subscription).length > 0 && (
          <>
            <p className={`${ROOT_CLASSNAME}__copy mb-2`}>
              This subscription was created on{' '}
              <strong>
                {format(parseISO(subscription.date_created), 'PPP')}
              </strong>
              .
            </p>

            <hr />

            <div className={`${ROOT_CLASSNAME}__address-wrapper`}>
              {!hasOnlyNonShippableProducts && (
                <div className={`${ROOT_CLASSNAME}__address`}>
                  <Heading
                    priority={2}
                    className={`${ROOT_CLASSNAME}__subheading`}
                  >
                    Delivery Address
                  </Heading>
                  <address className={`${ROOT_CLASSNAME}__copy`}>
                    {subscription.shipping.company && (
                      <>
                        {subscription.shipping.company}
                        <br />
                      </>
                    )}
                    {subscription.shipping.address_1 && (
                      <>
                        {subscription.shipping.address_1}
                        <br />
                      </>
                    )}
                    {subscription.shipping.address_2 && (
                      <>
                        {subscription.shipping.address_2}
                        <br />
                      </>
                    )}
                    {subscription.shipping.city && (
                      <>
                        {subscription.shipping.city}
                        <br />
                      </>
                    )}
                    {subscription.shipping.state && (
                      <>
                        {subscription.shipping.state}
                        <br />
                      </>
                    )}
                    {subscription.shipping.country && (
                      <>
                        {subscription.shipping.country}
                        <br />
                      </>
                    )}
                    {subscription.shipping.postcode && (
                      <>
                        {subscription.shipping.postcode}
                        <br />
                      </>
                    )}
                  </address>
                </div>
              )}

              <div className={`${ROOT_CLASSNAME}__address`}>
                <Heading
                  priority={2}
                  className={`${ROOT_CLASSNAME}__subheading`}
                >
                  Billing Address
                </Heading>
                <address className={`${ROOT_CLASSNAME}__copy`}>
                  {subscription.billing.company && (
                    <>
                      {subscription.billing.company}
                      <br />
                    </>
                  )}
                  {subscription.billing.address_1 && (
                    <>
                      {subscription.billing.address_1}
                      <br />
                    </>
                  )}
                  {subscription.billing.address_2 && (
                    <>
                      {subscription.billing.address_2}
                      <br />
                    </>
                  )}
                  {subscription.billing.city && (
                    <>
                      {subscription.billing.city}
                      <br />
                    </>
                  )}
                  {subscription.billing.state && (
                    <>
                      {subscription.billing.state}
                      <br />
                    </>
                  )}
                  {subscription.billing.country && (
                    <>
                      {subscription.billing.country}
                      <br />
                    </>
                  )}
                  {subscription.billing.postcode && (
                    <>
                      {subscription.billing.postcode}
                      <br />
                    </>
                  )}
                </address>
              </div>
            </div>

            <hr />

            {products && (
              <div className={`${ROOT_CLASSNAME}__products`}>
                <Heading
                  priority={2}
                  className={`${ROOT_CLASSNAME}__subheading`}
                >
                  Products
                </Heading>

                <div className={`${ROOT_CLASSNAME}__product-list`}>
                  {products.map((product) => {
                    if (!product) {
                      return null;
                    }

                    return (
                      <OrderProductCard
                        key={product.id}
                        className={`${ROOT_CLASSNAME}__product-card`}
                        product={product}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {!isExpiredOrCancelled && (
              <>
                <hr />

                <div className={`${ROOT_CLASSNAME}__total-wrapper`}>
                  <div className={`${ROOT_CLASSNAME}__details`}>
                    <Heading
                      priority={2}
                      className={`${ROOT_CLASSNAME}__subheading`}
                    >
                      Subscription Details
                    </Heading>

                    <div className={`${ROOT_CLASSNAME}__details-item`}>
                      <span className={`${ROOT_CLASSNAME}__details-label`}>
                        Billing Interval:
                      </span>
                      <span className={`${ROOT_CLASSNAME}__details-value`}>
                        {`${subscription.billing_interval} ${
                          subscription.billing_interval === '1'
                            ? 'payment'
                            : 'payments'
                        }`}{' '}
                        per month
                      </span>
                    </div>

                    <div className={`${ROOT_CLASSNAME}__details-item`}>
                      <span className={`${ROOT_CLASSNAME}__details-label`}>
                        Billing Period:
                      </span>
                      <span className={`${ROOT_CLASSNAME}__details-value`}>
                        Every {subscription.billing_period}
                      </span>
                    </div>

                    <div className={`${ROOT_CLASSNAME}__details-item`}>
                      <span className={`${ROOT_CLASSNAME}__details-label`}>
                        Next Payment Date:
                      </span>
                      <span className={`${ROOT_CLASSNAME}__details-value`}>
                        {subscription.next_payment_date
                          ? format(
                              parseISO(subscription.next_payment_date),
                              'PPP'
                            )
                          : 'N/A'}
                      </span>
                    </div>
                  </div>

                  <div className={`${ROOT_CLASSNAME}__totals`}>
                    <Heading
                      priority={2}
                      className={`${ROOT_CLASSNAME}__subheading`}
                    >
                      Cost Breakdown
                    </Heading>

                    <div className={`${ROOT_CLASSNAME}__total-item`}>
                      <span className={`${ROOT_CLASSNAME}__total-label`}>
                        Total Items
                      </span>
                      <span className={`${ROOT_CLASSNAME}__total-value`}>
                        {totalItems}
                      </span>
                    </div>

                    <div className={`${ROOT_CLASSNAME}__total-item`}>
                      <span className={`${ROOT_CLASSNAME}__total-label`}>
                        Subtotal
                      </span>
                      <span className={`${ROOT_CLASSNAME}__total-value`}>
                        {getPriceWithCurrency(subtotal)}
                      </span>
                    </div>

                    {discountTotal > 0 && (
                      <div className={`${ROOT_CLASSNAME}__total-item`}>
                        <span className={`${ROOT_CLASSNAME}__total-label`}>
                          Discount
                        </span>
                        <span className={`${ROOT_CLASSNAME}__total-value`}>
                          -{getPriceWithCurrency(discountTotal)}
                        </span>
                      </div>
                    )}

                    <div className={`${ROOT_CLASSNAME}__total-item`}>
                      <span className={`${ROOT_CLASSNAME}__total-label`}>
                        Tax
                      </span>
                      <span className={`${ROOT_CLASSNAME}__total-value`}>
                        {getPriceWithCurrency(taxTotal)}
                      </span>
                    </div>

                    {!hasOnlyNonShippableProducts && (
                      <div className={`${ROOT_CLASSNAME}__total-item`}>
                        <span className={`${ROOT_CLASSNAME}__total-label`}>
                          Shipping
                        </span>
                        <span className={`${ROOT_CLASSNAME}__total-value`}>
                          {getPriceWithCurrency(shippingTotal)}
                        </span>
                      </div>
                    )}

                    <div
                      className={`${ROOT_CLASSNAME}__total-item ${ROOT_CLASSNAME}__total-item--grand-total`}
                    >
                      <span
                        className={`${ROOT_CLASSNAME}__total-label ${ROOT_CLASSNAME}__total-label--grand-total`}
                      >
                        Total
                      </span>
                      <span
                        className={`${ROOT_CLASSNAME}__total-value ${ROOT_CLASSNAME}__total-value--grand-total`}
                      >{`£${subscription.total}`}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ViewSubscription;
