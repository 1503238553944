import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink/AnchorLink';
import { getValidURLQueries } from '@jpp/atoms/AnchorLink/utils';

import './TermsMenu.scss';

export interface ITermsMenuProps {
  className?: string;
}

export interface IStoreTermsMenuProps {
  menu: Core.IMenuItem[];
}

export type TTermsMenu = ITermsMenuProps & IStoreTermsMenuProps;

const ROOT_CLASSNAME = 'TermsMenu';

export const TermsMenu: FunctionComponent<TTermsMenu> = ({
  className,
  menu = [],
}) => {
  const router = useRouter();

  if (menu.length === 0 || !Array.isArray(menu)) {
    return null;
  }

  const query = getValidURLQueries(router.query);

  return (
    <ul className={classNames(ROOT_CLASSNAME, className)}>
      {menu.map(({ title, ID, slug }) => {
        const isInternalLink = title.toLowerCase() === 'deliveries';
        return (
          <li key={ID} className={`${ROOT_CLASSNAME}__item`}>
            {isInternalLink ? (
              <AnchorLink
                className={`${ROOT_CLASSNAME}__link`}
                withStyles={false}
                href={slug}
              >
                {title}
              </AnchorLink>
            ) : (
              <a
                className={`${ROOT_CLASSNAME}__link`}
                href={`${slug}?${query}`}
              >
                {title}
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
};
