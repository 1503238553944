import classNames from 'classnames';
import { Field, Form, FormikProps } from 'formik';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Checkbox from '@jpp/atoms/Checkbox';
import useAuthUser from '@jpp/hooks/useAuthUser';
import useGoogleReCaptcha, {
  EReCaptchaSize,
  IReCaptchaFormValues,
  RECAPTCHA_FIELDS,
} from '@jpp/hooks/useGoogleReCaptcha';
import Alert from '@jpp/molecules/Alert';
import { EAlertType } from '@jpp/molecules/Alert/Alert';
import Button from '@jpp/molecules/Button';
import Fieldset from '@jpp/molecules/Form/Fieldset';
import InputField from '@jpp/molecules/Form/InputField';
import Legend from '@jpp/molecules/Form/Legend';
import SelectField from '@jpp/molecules/Form/SelectField';
import { IBillingFormValues } from '@jpp/organisms/CheckoutForm/components/BillingFormFields/BillingFormFields';
import {
  ECountryCode,
  getCountryArray,
} from '@jpp/organisms/CheckoutForm/utils';
import ContainerLoader from '@jpp/organisms/Loader/ContainerLoader';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { ECtaTheme, ESize } from 'common/typings/enums';
import { TFuncVoid } from 'common/typings/types';

import './MyAccountDetailsForm.scss';

export interface IMyAccountDetailsFormProps {
  className?: string;
}

export interface IMyAccountDetailsFormValues extends IReCaptchaFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  sameAsShipping: boolean;
  marketing_opt_in: boolean;
  errorMessage?: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_address_1: string;
  billing_address_2: string;
  billing_city: string;
  billing_company: string;
  billing_country: string;
  billing_postcode: string;
  billing_state: string;
  shipping_first_name: string;
  shipping_last_name: string;
  shipping_address_1: string;
  shipping_address_2: string;
  shipping_city: string;
  shipping_company: string;
  shipping_country: string;
  shipping_postcode: string;
  shipping_state: string;
}

export interface IStoreMyAccountDetailsFormProps {
  user: IAuthStoreState;
}

export interface IDispatchMyAccountDetailsFormProps {
  onGetUser: TFuncVoid;
}

export type TMyAccountDetailsFormFields = Omit<
  IMyAccountDetailsFormValues,
  'errorMessage'
>;

export const MY_ACCOUNT_DETAILS_FORM_FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  marketing_opt_in: 'marketing_opt_in',
  sameAsShipping: 'sameAsShipping',
  billing_first_name: 'billing_first_name',
  billing_last_name: 'billing_last_name',
  billing_address_1: 'billing_address_1',
  billing_address_2: 'billing_address_2',
  billing_city: 'billing_city',
  billing_company: 'billing_company',
  billing_country: 'billing_country',
  billing_postcode: 'billing_postcode',
  billing_state: 'billing_state',
  shipping_first_name: 'shipping_first_name',
  shipping_last_name: 'shipping_last_name',
  shipping_address_1: 'shipping_address_1',
  shipping_address_2: 'shipping_address_2',
  shipping_city: 'shipping_city',
  shipping_company: 'shipping_company',
  shipping_country: 'shipping_country',
  shipping_postcode: 'shipping_postcode',
  shipping_state: 'shipping_state',
  ...RECAPTCHA_FIELDS,
};

export type TMyAccountDetailsForm = IMyAccountDetailsFormProps &
  IStoreMyAccountDetailsFormProps &
  IDispatchMyAccountDetailsFormProps;

const ROOT_CLASSNAME = 'MyAccountDetailsForm';

const MyAccountDetailsForm: React.FunctionComponent<
  TMyAccountDetailsForm & FormikProps<IMyAccountDetailsFormValues>
> = ({
  className,
  isValid,
  isSubmitting,
  setFieldValue,
  dirty,
  status,
  errors,
  submitCount,
  values,
}) => {
  const isFormSubmitDisabled = !isValid || isSubmitting || !dirty;
  const { email, billing = {} as IBillingFormValues } = useAuthUser();
  const { recaptchaRef, siteKey, onRecaptchaChange } = useGoogleReCaptcha(
    setFieldValue,
    MY_ACCOUNT_DETAILS_FORM_FIELDS.token,
    submitCount,
    dirty
  );
  const isSameAsShipping = values.sameAsShipping;

  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      {status && (
        <Alert
          className={`${ROOT_CLASSNAME}__alert ${ROOT_CLASSNAME}__alert--success`}
          isVisible={true}
          alertType={EAlertType.Success}
        >
          {status}
        </Alert>
      )}

      {errors?.errorMessage && (
        <Alert
          className={`${ROOT_CLASSNAME}__alert ${ROOT_CLASSNAME}__alert--error`}
          isVisible={true}
          alertType={EAlertType.Error}
        >
          {errors.errorMessage}
        </Alert>
      )}

      {isSubmitting && <ContainerLoader isVisible={true} />}

      <Form className={`${ROOT_CLASSNAME}__form`}>
        <Container fluid={true} className={`${ROOT_CLASSNAME}__container`}>
          <Fieldset>
            <Row className={`${ROOT_CLASSNAME}__row`}>
              <Flex colLg={12}>
                <Legend className={`${ROOT_CLASSNAME}__legend`}>
                  Contact Information
                </Legend>
              </Flex>

              <Flex colLg={12}>
                <InputField
                  label="Email/Username"
                  name="email"
                  value={email}
                  required={false}
                  disabled={true}
                />

                <InputField
                  label="First Name"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.firstName}
                  required={true}
                />

                <InputField
                  label="Last Name"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.lastName}
                  required={true}
                />

                <InputField
                  label="Phone Number"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.phoneNumber}
                  required={true}
                />

                <Field
                  className={`${ROOT_CLASSNAME}__checkbox-group`}
                  component={Checkbox}
                  label="I’d like to receive updates and offers from Gipsy Hill."
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.marketing_opt_in}
                />
              </Flex>
            </Row>
          </Fieldset>

          <Fieldset>
            <Row className={`${ROOT_CLASSNAME}__row`}>
              <Flex colLg={12}>
                <Legend
                  className={`${ROOT_CLASSNAME}__legend ${ROOT_CLASSNAME}__legend--shipping`}
                >
                  Shipping Information
                </Legend>
                <p
                  className={`${ROOT_CLASSNAME}__copy ${ROOT_CLASSNAME}__copy--notice`}
                >
                  Currently we only ship to UK Mainland .
                </p>
              </Flex>

              <Flex colLg={6}>
                <InputField
                  label="First Name (shipping only)"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_first_name}
                />

                <InputField
                  label="Last Name (shipping only)"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_last_name}
                />

                <InputField
                  label="Company"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_company}
                />

                <InputField
                  label="Address Line 1"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_address_1}
                />

                <InputField
                  label="Address Line 2"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_address_2}
                />
              </Flex>

              <Flex colLg={6}>
                <InputField
                  label="City"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_city}
                />

                <InputField
                  label="County"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_state}
                />

                <InputField
                  label="County"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_country}
                  disabled={true}
                  value={ECountryCode.GB}
                />

                <InputField
                  label="Post Code"
                  name={MY_ACCOUNT_DETAILS_FORM_FIELDS.shipping_postcode}
                />
              </Flex>
            </Row>
          </Fieldset>

          <>
            <Fieldset>
              <Row className={`${ROOT_CLASSNAME}__row`}>
                <Flex colLg={12}>
                  <Legend className={`${ROOT_CLASSNAME}__legend`}>
                    Billing Information
                    <Field
                      className={`${ROOT_CLASSNAME}__checkbox-group`}
                      component={Checkbox}
                      label="Use shipping information."
                      name={MY_ACCOUNT_DETAILS_FORM_FIELDS.sameAsShipping}
                    />
                  </Legend>
                </Flex>

                <Flex colLg={6}>
                  <InputField
                    label="First Name (billing only)"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_first_name}
                    disabled={isSameAsShipping}
                  />

                  <InputField
                    label="Last Name (billing only)"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_last_name}
                    disabled={isSameAsShipping}
                  />

                  <InputField
                    label="Company"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_company}
                    disabled={isSameAsShipping}
                  />

                  <InputField
                    label="Address Line 1"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_address_1}
                    disabled={isSameAsShipping}
                  />

                  <InputField
                    label="Address Line 2"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_address_2}
                    disabled={isSameAsShipping}
                  />
                </Flex>

                <Flex colLg={6}>
                  <InputField
                    label="City"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_city}
                    disabled={isSameAsShipping}
                  />

                  <InputField
                    label="County"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_state}
                    disabled={isSameAsShipping}
                  />

                  <SelectField
                    label="Country"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_country}
                    selectedOption={
                      ECountryCode[billing?.billing_country] || ECountryCode.GB
                    }
                    options={getCountryArray()}
                    required={true}
                    disabled={isSameAsShipping}
                  />

                  <InputField
                    label="Post Code"
                    name={MY_ACCOUNT_DETAILS_FORM_FIELDS.billing_postcode}
                    disabled={isSameAsShipping}
                  />
                </Flex>
              </Row>
            </Fieldset>
          </>

          <Fieldset>
            <Row className="text-center">
              <Flex colLg={12}>
                <ReCAPTCHA
                  className={`${ROOT_CLASSNAME}__recaptcha`}
                  ref={recaptchaRef}
                  size={EReCaptchaSize.Normal}
                  onChange={onRecaptchaChange}
                  sitekey={siteKey}
                />

                <Button
                  type="submit"
                  theme={
                    isFormSubmitDisabled ? ECtaTheme.TintPsi : ECtaTheme.Brand
                  }
                  behaviour="action"
                  size={ESize.Sm}
                  disabled={isFormSubmitDisabled}
                  className={classNames(`${ROOT_CLASSNAME}__button`, {
                    [`${ROOT_CLASSNAME}__button--is-disabled`]:
                      isFormSubmitDisabled,
                  })}
                >
                  Save
                </Button>
              </Flex>
            </Row>
          </Fieldset>
        </Container>
      </Form>
    </div>
  );
};

export default MyAccountDetailsForm;
