import { EBillingPeriod } from 'common/typings/enums';
import { IWooCommerceAPIOrder } from 'common/utils/transformers/order.transformer';

export type TCustomerSubscription = Omit<
  IWooCommerceAPISubscription,
  | 'parent_id'
  | 'order_key'
  | 'created_via'
  | 'version'
  | 'resubscribed_from'
  | 'resubscribed_subscription'
  | 'date_completed_gmt'
  | 'date_created_gmt'
  | 'date_modified_gmt'
  | 'date_paid_gmt'
  | 'customer_ip_address'
  | 'customer_user_agent'
  | 'transaction_id'
  | 'cart_hash'
  | 'meta_data'
  | '_links'
>;

export interface IAPICustomerSubscriptions {
  subscriptions: TCustomerSubscription[];
  currentPage: string;
  perPage: string;
  total: string;
  totalPages: string;
}

interface IWooCommerceAPIOrderTax {
  id: number;
  total: string;
  subtotal: string;
}

export interface IWooCommerceAPILineItem {
  id: number;
  name: string;
  product_id: number;
  variation_id: number;
  quantity: number;
  tax_class: string;
  subtotal: string;
  subtotal_tax: string;
  total: string;
  total_tax: string;
  taxes: IWooCommerceAPIOrderTax[];
  meta_data: any[];
  meta: any[];
  sku: string;
  price: number;
}

export interface IWooCommerceAPIShippingLine {
  id: number;
  method_title: string;
  method_id: string;
  instance_id: string;
  total: string;
  total_tax: string;
  taxes: any[];
  meta_data: any[];
}

export interface IWooCommerceAPISubscription extends IWooCommerceAPIOrder {
  billing_period: EBillingPeriod;
  billing_interval: number | string;
  resubscribed_from: string;
  resubscribed_subscription: string;
  start_date: string;
  trial_end_date: string;
  next_payment_date: string;
  end_date: string;
  removed_line_items: IWooCommerceAPILineItem[];
}

export const subscriptionTransformer = (
  subscriptions: IWooCommerceAPISubscription[] = []
): TCustomerSubscription[] =>
  subscriptions.map(
    ({
      parent_id,
      order_key,
      created_via,
      version,
      date_completed_gmt,
      date_created_gmt,
      date_modified_gmt,
      date_paid_gmt,
      customer_ip_address,
      customer_user_agent,
      transaction_id,
      cart_hash,
      meta_data,
      _links,
      resubscribed_from,
      resubscribed_subscription,
      ...subscription
    }) => subscription
  );

export const defaultCustomerSubscriptionState: IAPICustomerSubscriptions = {
  subscriptions: [],
  currentPage: '1',
  perPage: '10',
  total: '0',
  totalPages: '1',
};
