import { connect } from 'react-redux';

import { IReduxState } from 'common/redux/createStore';
import {
  getAllEventsItems,
  getFeaturedEventsItems,
} from 'common/redux/events/selectors';

import {
  ILinksWithEventsProps,
  IStoreLinksWithEventsProps,
  LinksWithEvents,
} from './LinksWithEvents';

const mapStateToProps = (state: IReduxState): IStoreLinksWithEventsProps => {
  const featuredEvents = getFeaturedEventsItems(state);
  const allEvents = getAllEventsItems(state);
  const events = featuredEvents?.length > 0 ? featuredEvents : allEvents;

  return {
    events,
  };
};

export default connect<
  IStoreLinksWithEventsProps,
  never,
  ILinksWithEventsProps
>(mapStateToProps)(LinksWithEvents);
