import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import OrderProductCard from '@jpp/molecules/OrderProductCard';
import { EMyAccountTab } from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import { EPageType } from 'common/typings/enums';
import { GIFT_CARD_PREFIX, SITE_TITLE } from 'common/utils/constants';
import { getPriceWithCurrency } from 'common/utils/shared/product';
import { TCustomerOrder } from 'common/utils/transformers/order.transformer';

import './ViewOrder.scss';

interface IViewOrderProps {
  className?: string;
  orderId: string;
  currentPage: string;
  order?: TCustomerOrder;
  isLoading: boolean;
}

const ROOT_CLASSNAME = 'ViewOrder';

const ViewOrder: React.FunctionComponent<IViewOrderProps> = ({
  className,
  orderId,
  currentPage = '1',
  order = {} as TCustomerOrder,
}) => {
  if (!orderId) {
    return null;
  }

  const {
    total,
    total_tax,
    shipping_total,
    shipping_tax,
    discount_tax,
    discount_total,
    status,
  } = order;
  const products = order?.line_items;
  const nonShippableProducts =
    products?.filter((item) => item.sku.includes(GIFT_CARD_PREFIX)) || [];
  const hasOnlyNonShippableProducts =
    nonShippableProducts.length === products?.length;
  const shippingTotal =
    shipping_total && shipping_tax
      ? parseFloat(shipping_total) + parseFloat(shipping_tax)
      : 0;
  const taxTotal =
    total_tax && shipping_tax
      ? parseFloat(total_tax) - parseFloat(shipping_tax)
      : 0;
  const discountTotal =
    discount_total && discount_tax
      ? parseFloat(discount_total) + parseFloat(discount_tax)
      : 0;
  const subtotal =
    total && total_tax
      ? parseFloat(total) -
        parseFloat(total_tax) -
        parseFloat(shipping_total) +
        discountTotal
      : 0;
  const totalItems = products
    ?.map((product) => product.quantity)
    .reduce((a, b) => a + b, 0);

  return (
    <>
      <Head>
        <title>
          Order #{orderId} | My Account | {SITE_TITLE}
        </title>
      </Head>

      <div className={classNames(className, ROOT_CLASSNAME)}>
        <Link
          href={`/${EPageType.MyAccount}/${EMyAccountTab.Orders}/?page=${currentPage}`}
        >
          <a className={`${ROOT_CLASSNAME}__link`}>{'< back to All Orders'}</a>
        </Link>

        <h1 className={`${ROOT_CLASSNAME}__heading`}>
          Order #{orderId}
          {status && (
            <span
              className={`${ROOT_CLASSNAME}__status ${ROOT_CLASSNAME}__status--${order.status}`}
            >
              {status.replace(/-/g, ' ')}
            </span>
          )}
        </h1>

        {(!order || (order && Object.keys(order).length === 0)) && (
          <p className={`${ROOT_CLASSNAME}__copy mt-1`}>
            Sorry we couldn't find an order with that order number.
          </p>
        )}

        {order && Object.keys(order).length > 0 && (
          <>
            <p className={`${ROOT_CLASSNAME}__copy mb-2`}>
              This order was created on{' '}
              <strong>{format(parseISO(order.date_created), 'PPP')}</strong>.
            </p>

            <hr />

            <div className={`${ROOT_CLASSNAME}__address-wrapper`}>
              {!hasOnlyNonShippableProducts && (
                <div className={`${ROOT_CLASSNAME}__address`}>
                  <Heading
                    priority={2}
                    className={`${ROOT_CLASSNAME}__subheading`}
                  >
                    Delivery Address
                  </Heading>
                  <address className={`${ROOT_CLASSNAME}__copy`}>
                    {order.shipping.company && (
                      <>
                        {order.shipping.company}
                        <br />
                      </>
                    )}
                    {order.shipping.address_1 && (
                      <>
                        {order.shipping.address_1}
                        <br />
                      </>
                    )}
                    {order.shipping.address_2 && (
                      <>
                        {order.shipping.address_2}
                        <br />
                      </>
                    )}
                    {order.shipping.city && (
                      <>
                        {order.shipping.city}
                        <br />
                      </>
                    )}
                    {order.shipping.state && (
                      <>
                        {order.shipping.state}
                        <br />
                      </>
                    )}
                    {order.shipping.country && (
                      <>
                        {order.shipping.country}
                        <br />
                      </>
                    )}
                    {order.shipping.postcode && (
                      <>
                        {order.shipping.postcode}
                        <br />
                      </>
                    )}
                  </address>
                </div>
              )}

              <div className={`${ROOT_CLASSNAME}__address`}>
                <Heading
                  priority={2}
                  className={`${ROOT_CLASSNAME}__subheading`}
                >
                  Billing Address
                </Heading>
                <address className={`${ROOT_CLASSNAME}__copy`}>
                  {order.billing.company && (
                    <>
                      {order.billing.company}
                      <br />
                    </>
                  )}
                  {order.billing.address_1 && (
                    <>
                      {order.billing.address_1}
                      <br />
                    </>
                  )}
                  {order.billing.address_2 && (
                    <>
                      {order.billing.address_2}
                      <br />
                    </>
                  )}
                  {order.billing.city && (
                    <>
                      {order.billing.city}
                      <br />
                    </>
                  )}
                  {order.billing.state && (
                    <>
                      {order.billing.state}
                      <br />
                    </>
                  )}
                  {order.billing.country && (
                    <>
                      {order.billing.country}
                      <br />
                    </>
                  )}
                  {order.billing.postcode && (
                    <>
                      {order.billing.postcode}
                      <br />
                    </>
                  )}
                </address>
              </div>
            </div>

            <hr />

            {products && (
              <div className={`${ROOT_CLASSNAME}__products`}>
                <Heading
                  priority={2}
                  className={`${ROOT_CLASSNAME}__subheading`}
                >
                  Products
                </Heading>

                <div className={`${ROOT_CLASSNAME}__product-list`}>
                  {products.map((product) => {
                    if (!product) {
                      return null;
                    }

                    return (
                      <OrderProductCard
                        key={product.id}
                        className={`${ROOT_CLASSNAME}__product-card`}
                        product={product}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            <hr />

            <div className={`${ROOT_CLASSNAME}__totals`}>
              <Heading priority={2} className={`${ROOT_CLASSNAME}__subheading`}>
                Cost Breakdown
              </Heading>

              <div className={`${ROOT_CLASSNAME}__total-item`}>
                <span className={`${ROOT_CLASSNAME}__total-label`}>
                  Total Items
                </span>
                <span className={`${ROOT_CLASSNAME}__total-value`}>
                  {totalItems}
                </span>
              </div>

              <div className={`${ROOT_CLASSNAME}__total-item`}>
                <span className={`${ROOT_CLASSNAME}__total-label`}>
                  Subtotal
                </span>
                <span className={`${ROOT_CLASSNAME}__total-value`}>
                  {getPriceWithCurrency(subtotal)}
                </span>
              </div>

              {discountTotal > 0 && (
                <div className={`${ROOT_CLASSNAME}__total-item`}>
                  <span className={`${ROOT_CLASSNAME}__total-label`}>
                    Discount
                  </span>
                  <span className={`${ROOT_CLASSNAME}__total-value`}>
                    -{getPriceWithCurrency(discountTotal)}
                  </span>
                </div>
              )}

              <div className={`${ROOT_CLASSNAME}__total-item`}>
                <span className={`${ROOT_CLASSNAME}__total-label`}>Tax</span>
                <span className={`${ROOT_CLASSNAME}__total-value`}>
                  {getPriceWithCurrency(taxTotal)}
                </span>
              </div>

              {!hasOnlyNonShippableProducts && (
                <div className={`${ROOT_CLASSNAME}__total-item`}>
                  <span className={`${ROOT_CLASSNAME}__total-label`}>
                    Shipping
                  </span>
                  <span className={`${ROOT_CLASSNAME}__total-value`}>
                    {getPriceWithCurrency(shippingTotal)}
                  </span>
                </div>
              )}

              <div
                className={`${ROOT_CLASSNAME}__total-item ${ROOT_CLASSNAME}__total-item--grand-total`}
              >
                <span
                  className={`${ROOT_CLASSNAME}__total-label ${ROOT_CLASSNAME}__total-label--grand-total`}
                >
                  Total
                </span>
                <span
                  className={`${ROOT_CLASSNAME}__total-value ${ROOT_CLASSNAME}__total-value--grand-total`}
                >{`${order.currency_symbol}${order.total}`}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewOrder;
