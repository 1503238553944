import { connect } from 'react-redux';

import { getOptions } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';

import { ISocialMenuProps, SocialMenu } from './SocialMenu';

export interface IStoreSocialMenuProps {
  facebook: string;
  twitter: string;
  untappd: string;
  instagram: string;
}

const mapStateToProps = (state: IReduxState): IStoreSocialMenuProps => {
  const { facebook, twitter, untappd, instagram } = getOptions(state);
  return {
    facebook,
    twitter,
    untappd,
    instagram,
  };
};

export default connect<IStoreSocialMenuProps, never, ISocialMenuProps>(
  mapStateToProps
)(SocialMenu);
