import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { EIconType } from '@jpp/atoms/Icon/utils/types';
import SocialItem from '@jpp/atoms/SocialItem';
import { IStoreSocialMenuProps } from '@jpp/molecules/SocialMenu';
import { EDisplayMode } from 'common/typings/enums';

import './SocialMenu.scss';

export interface ISocialMenuProps {
  className?: string;
  displayMode?: EDisplayMode;
}

type TSocialMenu = ISocialMenuProps & IStoreSocialMenuProps;

export const SocialMenu: FunctionComponent<TSocialMenu> = ({
  className,
  facebook,
  twitter,
  untappd,
  instagram,
  displayMode = EDisplayMode.Inline,
}) => {
  const defaultItemProps = {
    className: 'SocialMenu__item',
  };

  const socialArray = [
    {
      url: instagram,
      icon: EIconType.Instagram,
    },
    {
      url: twitter,
      icon: EIconType.Twitter,
    },
    {
      url: facebook,
      icon: EIconType.Facebook,
    },
    {
      url: untappd,
      icon: EIconType.Untappd,
    },
  ];

  return (
    <nav
      className={classNames('SocialMenu', className, {
        'SocialMenu--display-block': displayMode === EDisplayMode.Block,
      })}
    >
      <ul className="SocialMenu__list">
        {socialArray.map(({ url, icon }, index) => {
          if (!url) {
            return null;
          }

          return (
            <SocialItem
              {...defaultItemProps}
              key={`${url} ${index}`}
              name={icon}
              link={url}
            />
          );
        })}
      </ul>
    </nav>
  );
};
