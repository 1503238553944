import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { getValidURLQueries } from '@jpp/atoms/AnchorLink/utils';
import Icon from '@jpp/atoms/Icon/Icon';
import { EPageType } from 'common/typings/enums';

import './ShoppingCartIcon.scss';

export interface IShoppingCartProps {
  className?: string;
}

export interface IStoreShoppingCartProps {
  totalItems: number;
}

type TShoppingCart = IShoppingCartProps & IStoreShoppingCartProps;

const ROOT_CLASSNAME = 'ShoppingCartIcon';

export const ShoppingCartIcon: React.FunctionComponent<TShoppingCart> = ({
  className,
  totalItems,
}) => {
  const isGreaterThan99 = totalItems > 99;
  const router = useRouter();

  return (
    <Link
      href={{
        pathname: `/${EPageType.Cart}`,
        query: getValidURLQueries(router.query, true),
      }}
    >
      <a
        className={classNames(ROOT_CLASSNAME, className, {
          [`${ROOT_CLASSNAME}--empty`]: totalItems === 0,
        })}
      >
        {totalItems > 0 && (
          <>
            <span className={`${ROOT_CLASSNAME}__circle`} />
            <span
              className={classNames([`${ROOT_CLASSNAME}__count`], {
                [`${ROOT_CLASSNAME}__count--is-greater-than-99`]:
                  isGreaterThan99,
              })}
            >
              {isGreaterThan99 ? '99+' : totalItems}
            </span>
          </>
        )}

        {totalItems === 0 ? (
          <Icon.Box
            className={`${ROOT_CLASSNAME}__icon ${ROOT_CLASSNAME}__icon--empty`}
          />
        ) : (
          <Icon.BoxOpen className={`${ROOT_CLASSNAME}__icon`} />
        )}
      </a>
    </Link>
  );
};
