import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon from '@jpp/atoms/Icon';
import { EIconType } from '@jpp/atoms/Icon/utils/types';
import { ETheme } from 'common/typings/enums';

import './SocialItem.scss';

interface ISocialItemProps {
  className?: string;
  name: EIconType;
  link: string;
  theme?: ETheme;
  onClick: Core.TOnClick;
}

const ROOT_CLASSNAME = 'SocialItem';

const SocialItem: FunctionComponent<Partial<ISocialItemProps>> = ({
  name,
  link,
  onClick,
  theme = ETheme.TintAlpha,
  children,
  className,
}) => {
  if (!name) {
    return null;
  }

  const SocialIcon = Icon[name];

  return (
    <li className={classNames(ROOT_CLASSNAME, className, `fill-${theme}`)}>
      <a
        className={`${ROOT_CLASSNAME}__link`}
        href={`https://www.${name}.com/${link}`}
        aria-label={name}
        target="_blank"
        onClick={onClick}
        rel="noopener noreferrer"
      >
        <SocialIcon
          className={classNames(`${ROOT_CLASSNAME}__icon`, {
            [`${ROOT_CLASSNAME}__icon--untappd`]: name === EIconType.Untappd,
            [`${ROOT_CLASSNAME}__icon--twitter`]: name === EIconType.Twitter,
          })}
          inline={true}
        />

        {children && (
          <>
            <div className={`${ROOT_CLASSNAME}__content`}>{children}</div>
          </>
        )}
      </a>
    </li>
  );
};

export default SocialItem;
