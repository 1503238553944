import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import internalAxiosInstance from 'common/utils/axios/internal';
import { getPriceWithCurrency } from 'common/utils/shared/product';
import { IWooCommerceAPILineItem } from 'common/utils/transformers/order.transformer';
import { IProductAPIState } from 'common/utils/transformers/products.transformer';

import './OrderProductCard.scss';

interface IOrderProductCardProps {
  className?: string;
  product: IWooCommerceAPILineItem;
}

const ROOT_CLASSNAME = 'OrderProductCard';

const OrderProductCard: React.FunctionComponent<IOrderProductCardProps> = ({
  className,
  product: { product_id, name, quantity, total, total_tax },
}) => {
  const [product, setProduct] = React.useState<IProductAPIState>({} as any);
  const fetchProduct = async () => {
    await internalAxiosInstance
      .get(`/products/${product_id}`)
      .then((response) => setProduct(response.data));
  };

  React.useEffect(() => {
    fetchProduct().catch((e) => console.error(e));
  }, []);

  if (!product) {
    return null;
  }

  const image = product?.images && product.images[0];

  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
        {image && image.src && (
          <RefImage
            image={{ url: image.src, alt: `${name} image` } as Core.IMedia}
            className={`${ROOT_CLASSNAME}__image`}
          />
        )}
      </div>

      <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
        <Heading
          className={`${ROOT_CLASSNAME}__heading`}
          priority={3}
          children={name}
        />

        <p className={`${ROOT_CLASSNAME}__copy`}>Qty: {quantity}</p>

        <p className={`${ROOT_CLASSNAME}__copy display-none`}>
          Total:{' '}
          {getPriceWithCurrency(parseFloat(total) + parseFloat(total_tax))}
        </p>
      </div>
    </div>
  );
};

export default OrderProductCard;
