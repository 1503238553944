import classNames from 'classnames';
import React from 'react';

import MegaMenuAuthItem from '@jpp/molecules/MegaMenu/AuthItem';
import MegaMenuItem from '@jpp/molecules/MegaMenu/Item';
import { EPageType } from 'common/typings/enums';

import ConfigProvider from '../../../services/configProvider';
import '../SubNavigation/SubNavigation.scss';
import './MegaMenu.scss';

export interface IMegaMenuProps {
  className?: string;
}

export interface IStoreMegaMenuProps {
  menu: Core.IMenuItem[];
}

export type TMegaMenu = IMegaMenuProps & IStoreMegaMenuProps;

const ROOT_CLASSNAME = 'MegaMenu';

const MegaMenu: React.FunctionComponent<TMegaMenu> = ({ className, menu }) => {
  if (!menu || !Array.isArray(menu)) {
    return null;
  }

  const isAuthEnabled = ConfigProvider.getValue('AUTH_ENABLED') === 'true';
  const MAX_ITEM_INDEX = isAuthEnabled ? 3 : 4;

  return (
    <nav className={classNames(ROOT_CLASSNAME, className)}>
      <ul className={`${ROOT_CLASSNAME}__list`}>
        {menu.map((menuItem, index) => {
          if (index === 4 && isAuthEnabled) {
            return (
              <MegaMenuAuthItem
                key="auth"
                className={`${ROOT_CLASSNAME}__item`}
                linkClassName={`${ROOT_CLASSNAME}__link`}
              />
            );
          }

          if (index > MAX_ITEM_INDEX) {
            return null;
          }

          return (
            <MegaMenuItem
              {...menuItem}
              key={menuItem.ID}
              pageType={menuItem.pageType as EPageType}
              className={`${ROOT_CLASSNAME}__item`}
              linkClassName={`${ROOT_CLASSNAME}__link`}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default MegaMenu;
