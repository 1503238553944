import classNames from 'classnames';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import { IPdpProduct } from 'common/redux/pdp/reducer';
import { EPageType } from 'common/typings/enums';

import './MegaMenuProductCard.scss';

interface IMegaMenuProductCardProps {
  className?: string;
  product: IPdpProduct;
}

const ROOT_CLASSNAME = 'MegaMenuProductCard';

export const MegaMenuProductCard: React.FunctionComponent<
  IMegaMenuProductCardProps
> = ({ className, product }) => {
  const { post_title, acf_fields, post_name } = product;
  const acf = acf_fields || product.acf;
  const slug = post_name || product.slug;
  const image =
    product.featured_image || product.image || acf?.default_product.image;
  const href = `/${EPageType.Product}/${slug}`;
  const title = post_title || product.title;

  return (
    <AnchorLink href={href} className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__header`}>
        {image && (
          <RefImage
            className={`${ROOT_CLASSNAME}__image`}
            image={image as Core.IMedia}
            shouldLazyLoad={false}
          />
        )}
      </div>

      <Heading
        className={`${ROOT_CLASSNAME}__heading`}
        priority={4}
        children={title}
      />

      <span className={`${ROOT_CLASSNAME}__cta`}>View</span>
    </AnchorLink>
  );
};
