import { connect } from 'react-redux';

import { getMenu, getMenuState } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';
import { setMenuState } from 'common/redux/rootActions';
import { EMenuType } from 'common/typings/enums';
import { TFuncValueVoid } from 'common/typings/types';

import PrimaryHeader, { IPrimaryHeaderProps } from './PrimaryHeader';

export interface IStorePrimaryHeaderProps {
  isMenuOpen: boolean;
  menu: Core.IMenuItem[];
}

export interface IDispatchPrimaryHeaderProps {
  setMenuState: TFuncValueVoid<boolean>;
}

const mapStateToProps = (state: IReduxState): IStorePrimaryHeaderProps => ({
  isMenuOpen: getMenuState(state),
  menu: getMenu(state, EMenuType.PrimaryMenu),
});

const mapDispatchToProps: IDispatchPrimaryHeaderProps = {
  setMenuState,
};

export default connect<
  IStorePrimaryHeaderProps,
  IDispatchPrimaryHeaderProps,
  IPrimaryHeaderProps
>(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryHeader);
