import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import Icon from '@jpp/atoms/Icon/Icon';

import FooterMenu from '../FooterMenu';
import './FooterAccordion.scss';

interface IFooterAccordionProps {
  className?: string;
  heading: string;
  menu?: Core.IMenuItem[];
  content?: string | JSX.Element;
}

const ROOT_CLASSNAME = 'FooterAccordion';

const renderContent = (content: IFooterAccordionProps['content']) => {
  if (typeof content === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return content;
};

export const FooterAccordion: React.FunctionComponent<
  IFooterAccordionProps
> = ({ className, heading, menu, content }) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const handleOnClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    setIsVisible(!isVisible);
  };

  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <Heading
        priority={4}
        className="FooterAccordion__heading"
        isInnerHTML={false}
      >
        <a
          className="FooterAccordion__heading-link"
          role="button"
          onClick={handleOnClick}
        >
          {heading}
        </a>

        <Icon.Arrow
          className={classNames('FooterAccordion__icon', {
            'FooterAccordion__icon--is-active': isVisible,
          })}
          inline={true}
        />
      </Heading>

      <div
        className={classNames('FooterAccordion__content', {
          'FooterAccordion__content--is-hidden': !isVisible,
        })}
      >
        {menu && !content && (
          <FooterMenu className="FooterAccordion__menu" menu={menu} />
        )}

        {content && !menu && renderContent(content)}
      </div>
    </div>
  );
};
