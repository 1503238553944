import { format, parseISO } from 'date-fns';
import Link from 'next/link';
import React, { useCallback, useState } from 'react';

import ContainerLoader from '@jpp/organisms/Loader/ContainerLoader';
import { EditSubscriptionModal } from '@jpp/organisms/Modal';
import { EMyAccountTab } from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import Pagination from '@jpp/organisms/Pagination';
import { EPageType, ESubscriptionStatus } from 'common/typings/enums';
import { TFuncVoid } from 'common/typings/types';
import { getPriceWithCurrency } from 'common/utils/shared/product';
import {
  IAPICustomerSubscriptions,
  TCustomerSubscription,
} from 'common/utils/transformers/subscription.transformer';

const ROOT_CLASSNAME = 'Subscriptions';

export type TAllSubscriptions = IAPICustomerSubscriptions & {
  hasFetched: boolean;
  isLoading: boolean;
};

const AllSubscriptions: React.FunctionComponent<
  TAllSubscriptions & { onReFetchSubscriptions: TFuncVoid }
> = ({
  subscriptions,
  currentPage,
  total: totalItems,
  totalPages,
  isLoading,
  hasFetched,
  onReFetchSubscriptions,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editSub, setEditSub] = useState<TCustomerSubscription | undefined>(
    undefined
  );
  const hrefBase = `/${EPageType.MyAccount}/${EMyAccountTab.Subscriptions}`;

  // const handleSubscriptionEditClick = (val: number) => {
  //   setEditSub(subscriptions.find(sub => String(sub.id) === String(val)));
  //   setIsModalVisible(true);
  // };

  const handleSubModalOnClose = useCallback(() => {
    setEditSub(undefined);
    setIsModalVisible(false);
    onReFetchSubscriptions();
  }, []);

  return (
    <>
      {isModalVisible && editSub && (
        <EditSubscriptionModal
          isVisible={isModalVisible}
          className={`${ROOT_CLASSNAME}__edit-subscription-modal`}
          onClose={handleSubModalOnClose}
          bodyOverflow={false}
          subscription={editSub}
        />
      )}

      <h1 className={`${ROOT_CLASSNAME}__heading`}>
        Your Subscriptions
        {parseInt(totalPages, 10) > 0 && (
          <span
            className={`${ROOT_CLASSNAME}__page-range`}
          >{`Page ${currentPage} of ${totalPages}`}</span>
        )}
      </h1>

      <p className={`${ROOT_CLASSNAME}__copy ${ROOT_CLASSNAME}__copy--notice`}>
        On smaller devices please scroll right to view all the information
        below.
      </p>

      {(!hasFetched || isLoading) && (
        <div className={`${ROOT_CLASSNAME}__empty`}>
          <ContainerLoader isVisible={true} />
        </div>
      )}

      {hasFetched && !isLoading && (
        <>
          {subscriptions?.length === 0 ? (
            <p className={`${ROOT_CLASSNAME}__copy`}>
              You don't appear to have any subscriptions. If this is a mistake
              please contact support info@gipsyhillbrewing.com.
            </p>
          ) : (
            <>
              <div className={`${ROOT_CLASSNAME}__table-wrapper`}>
                <table className={`${ROOT_CLASSNAME}__table`}>
                  <thead className={`${ROOT_CLASSNAME}__thead`}>
                    <tr className={`${ROOT_CLASSNAME}__tr`}>
                      <th className={`${ROOT_CLASSNAME}__th`}>ID</th>
                      <th
                        className={`${ROOT_CLASSNAME}__th ${ROOT_CLASSNAME}__th--date`}
                      >
                        Date
                      </th>
                      <th
                        className={`${ROOT_CLASSNAME}__th ${ROOT_CLASSNAME}__th--total`}
                      >
                        Total
                      </th>
                      <th className={`${ROOT_CLASSNAME}__th`}>
                        Billing&nbsp;Period
                      </th>
                      <th
                        className={`${ROOT_CLASSNAME}__th ${ROOT_CLASSNAME}__th--status`}
                      >
                        Status
                      </th>
                      <th className={`${ROOT_CLASSNAME}__th`}>
                        Next&nbsp;Renewal
                      </th>
                      <th
                        className={`${ROOT_CLASSNAME}__th ${ROOT_CLASSNAME}__th--actions`}
                      />
                    </tr>
                  </thead>

                  <tbody className={`${ROOT_CLASSNAME}__tbody`}>
                    {subscriptions?.map((subscription) => {
                      if (!subscription) {
                        return null;
                      }

                      const isActive =
                        subscription.status === ESubscriptionStatus.Active;
                      // const isOnHold = subscription.status === ESubscriptionStatus.OnHold;
                      const price = parseFloat(subscription.total);

                      return (
                        <tr
                          className={`${ROOT_CLASSNAME}__tr`}
                          key={subscription.id}
                        >
                          <td className={`${ROOT_CLASSNAME}__td`}>
                            #{subscription.id}
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td ${ROOT_CLASSNAME}__td--date`}
                          >
                            {format(parseISO(subscription.date_created), 'PPP')}
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td ${ROOT_CLASSNAME}__td--total`}
                          >
                            {price === 0 ? (
                              <strong>FREE</strong>
                            ) : (
                              getPriceWithCurrency(price)
                            )}
                          </td>

                          <td className={`${ROOT_CLASSNAME}__td`}>
                            Every {subscription.billing_period}
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td ${ROOT_CLASSNAME}__td--status ${ROOT_CLASSNAME}__td--${subscription.status}`}
                          >
                            <span>
                              {subscription?.status?.replace(/-/g, ' ')}
                            </span>
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td text-align-center`}
                          >
                            {isActive && (
                              <>
                                {subscription?.next_payment_date &&
                                  format(
                                    parseISO(subscription.next_payment_date),
                                    'PPP'
                                  )}
                              </>
                            )}
                          </td>

                          <td
                            className={`${ROOT_CLASSNAME}__td ${ROOT_CLASSNAME}__td--actions`}
                          >
                            <Link href={`${hrefBase}?id=${subscription.id}`}>
                              <a className={`${ROOT_CLASSNAME}__link`}>View</a>
                            </Link>
                            {/*{(isActive || isOnHold) && (*/}
                            {/*  <>*/}
                            {/*    &nbsp;/&nbsp;*/}
                            {/*    <button type="button" onClick={() => handleSubscriptionEditClick(subscription.id)}*/}
                            {/*            className={`${ROOT_CLASSNAME}__edit-btn`}>*/}
                            {/*      Edit*/}
                            {/*    </button>*/}
                            {/*  </>*/}
                            {/*)}*/}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {parseInt(totalPages, 10) > 1 && (
                <Pagination
                  currentPage={parseInt(currentPage, 10)}
                  totalItems={parseInt(totalItems, 10)}
                  totalPages={parseInt(totalPages, 10)}
                  hrefBase={hrefBase}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AllSubscriptions;
