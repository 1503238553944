import React from 'react';
import { useSelector } from 'react-redux';

import { IReduxState } from 'common/redux/createStore';
import {
  ICartLineItem,
  ICartTotals,
} from 'common/redux/wooCommerce/cart/reducer';
import {
  getCartLineItems,
  getCartTotals,
} from 'common/redux/wooCommerce/cart/selectors';
import {
  getDiscountAmount,
  getPriceWithoutCurrency,
  getSubTotal,
} from 'common/utils/shared/product';
import { getStripePriceFromString } from 'common/utils/shared/stripe';

const useCartTotals = () => {
  const { cartTotals } = useSelector<
    IReduxState,
    {
      cartTotals: ICartTotals;
      cartLineItems: ICartLineItem[];
    }
  >((state) => ({
    cartTotals: getCartTotals(state),
    cartLineItems: getCartLineItems(state),
  }));
  const { subtotal_tax, discount_total, discount_tax } = cartTotals;
  const subtotal = getPriceWithoutCurrency(
    getSubTotal(cartTotals.subtotal, subtotal_tax, discount_total, discount_tax)
  );
  const [totals] = React.useState({
    subtotal: getStripePriceFromString(subtotal),
  });

  return {
    subtotal: totals.subtotal,
    cartTotals,
    discount: getDiscountAmount(discount_total, discount_tax),
    totalAmount: parseFloat(getPriceWithoutCurrency(cartTotals.total)),
    cartContentTotal:
      parseFloat(getPriceWithoutCurrency(cartTotals.cart_contents_total)) +
      parseFloat(getPriceWithoutCurrency(cartTotals.cart_contents_tax)),
    shippingAmount:
      parseFloat(getPriceWithoutCurrency(cartTotals.shipping_total)) +
      parseFloat(getPriceWithoutCurrency(cartTotals.shipping_tax)),
  };
};

export default useCartTotals;
