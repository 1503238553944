import * as Sentry from '@sentry/node';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';

import useIsMounted from '@jpp/hooks/useIsMounted';
import useQueryParam from '@jpp/hooks/useQueryParam';
import ViewOrder from '@jpp/organisms/MyAccount/TabGroup/panels/Orders/components/ViewOrder';
import { TTabGroupChildProps } from '@jpp/organisms/Tab/Group/TabGroup';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { getAuth } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import axios from 'common/utils/axios/internal';
import {
  defaultCustomerOrderState,
  IAPICustomerOrders,
  TCustomerOrder,
} from 'common/utils/transformers/order.transformer';

import AllOrders, { TAllOrders } from './components/AllOrders';
import './Orders.scss';

const ROOT_CLASSNAME = 'Orders';

const Orders: React.FunctionComponent<TTabGroupChildProps> = ({
  className,
  parentClassName,
}) => {
  const router = useRouter();
  const isMounted = useIsMounted();
  const orderId = useQueryParam('id');
  const user = useSelector<IReduxState, IAuthStoreState>((state) =>
    getAuth(state)
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [orderData, setOrderData] = React.useState<TAllOrders>({
    ...defaultCustomerOrderState,
    hasFetched: false,
    isLoading,
  });
  const [order, setOrder] = React.useState<TCustomerOrder | undefined>(
    {} as TCustomerOrder
  );

  const getOrderData = async () => {
    if (user?.id) {
      setIsLoading(true);
      try {
        const page = router.query.page || orderData.currentPage || 1;
        const per_page = router.query.per_page || 10;
        const { data } = await axios.get<IAPICustomerOrders>(
          `/customer/orders/${user.id}/${page}/${per_page}`
        );

        if (isMounted.current) {
          setIsLoading(false);
          setOrderData({
            ...data,
            hasFetched: true,
            isLoading: false,
          });

          if (orderId) {
            setOrder(data.orders.find((o) => o.number === orderId));
          }
        }
      } catch (e) {
        setIsLoading(false);
        Sentry.captureException(e);
      }
    }
  };

  React.useEffect(() => {
    if (isMounted.current) {
      getOrderData();
    }
  }, [router.query.page]);

  React.useEffect(() => {
    if (isMounted.current) {
      setOrder(orderData.orders.find((o) => o.number === orderId));
      setIsLoading(false);
    }
  }, [orderId]);

  if (!orderData) {
    return null;
  }

  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${parentClassName}__panel`
      )}
    >
      <div className={`${parentClassName}__panel-group`}>
        {orderId ? (
          <ViewOrder
            orderId={orderId}
            currentPage={orderData.currentPage}
            order={order}
            isLoading={isLoading}
          />
        ) : (
          <AllOrders {...orderData} isLoading={isLoading} />
        )}
      </div>
    </div>
  );
};

export default Orders;
