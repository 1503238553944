import classNames from 'classnames';
import Link from 'next/link';
import React, { ReactText } from 'react';

import Icons from '@jpp/atoms/Icon';

import './Pagination.scss';

export interface IPaginationProps {
  className?: string;
  currentPage: number;
  totalItems: number;
  totalPages: number;
  hrefBase: string;
}

export type TPagination = IPaginationProps;

const ROOT_CLASSNAME = 'Pagination';
const PAGE_THRESHOLD = 5;
const MAX_PAGINATION_ITEMS = 3;
const ELLIPSIS = '...';
const Arrow = Icons.Arrow;

class Pagination extends React.PureComponent<TPagination> {
  get totalPages(): number {
    return this.props.totalPages;
  }

  get paginationPages(): ReactText[] {
    let diff: number;
    const pages: ReactText[] = [];

    if (this.totalPages <= PAGE_THRESHOLD) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }

      return pages;
    }

    const { currentPage } = this.props;

    let startPage: number = currentPage - 1;
    let endPage: number = currentPage + 1;

    if (startPage < 2) {
      diff = 2 - startPage;
      endPage += diff;
      startPage += diff;
    }

    if (endPage > this.totalPagesLessX(1)) {
      diff = endPage - this.totalPagesLessX(1);
      endPage -= diff;
      startPage -= diff;
    }

    const hasStartEllipsis = currentPage > MAX_PAGINATION_ITEMS;
    const hasEndEllipsis = currentPage < this.totalPagesLessX(2);

    pages.push(1);
    pages.push(hasStartEllipsis ? ELLIPSIS : startPage);

    // If we have a beginning and end ellipsis then we need to remove the subtractor from the loop
    // as we need numbers either side of the middle value. e.g [1, '...'
    const subtractor = hasStartEllipsis && hasEndEllipsis ? 0 : 1;

    for (let i = startPage + subtractor; i <= endPage - subtractor; i += 1) {
      pages.push(i);
    }

    pages.push(hasEndEllipsis ? ELLIPSIS : endPage);
    pages.push(this.totalPages);

    return pages;
  }

  get paginationPagesElement(): JSX.Element[] {
    const pages = this.paginationPages;
    const itemClass = `${ROOT_CLASSNAME}__item`;
    const linkClass = `${ROOT_CLASSNAME}__link`;
    const spanClass = `${ROOT_CLASSNAME}__number-span`;

    return pages.map((item, index) => {
      const isActive = item === this.props.currentPage;

      if (item === ELLIPSIS) {
        return (
          <li
            key={index}
            className={classNames(itemClass, itemClass + '--ellipsis')}
          >
            {item}
          </li>
        );
      }

      if (isActive) {
        return (
          <li
            key={index}
            className={classNames(
              itemClass,
              itemClass + '--page-number',
              itemClass + '--is-active'
            )}
          >
            <span className={classNames(spanClass)}>{item}</span>
          </li>
        );
      }

      return (
        <li
          key={index}
          className={classNames(itemClass, itemClass + '--page-number')}
        >
          <Link href={`${this.props.hrefBase}?page=${item}`}>
            <a className={linkClass}>{item}</a>
          </Link>
        </li>
      );
    });
  }

  get previousPage(): number {
    return this.props.currentPage - 1;
  }

  get nextPage(): number {
    return this.props.currentPage + 1;
  }

  totalPagesLessX = (value: number) => this.totalPages - value;

  render() {
    const { className, currentPage, hrefBase } = this.props;
    const showPrevious = currentPage > 1;
    const showNext = currentPage < this.totalPages;

    return (
      <article className={classNames(className, ROOT_CLASSNAME)}>
        <nav className={`${ROOT_CLASSNAME}__nav`}>
          <ul className={`${ROOT_CLASSNAME}__list`}>
            {showPrevious && (
              <li
                className={classNames(
                  `${ROOT_CLASSNAME}__item`,
                  `${ROOT_CLASSNAME}__item--prev`
                )}
              >
                <Link href={`${hrefBase}?page=${this.previousPage}`}>
                  <a>
                    <Arrow
                      inline={true}
                      className={`${ROOT_CLASSNAME}__prev-arrow`}
                      aria-label="Previous"
                    />
                  </a>
                </Link>
              </li>
            )}

            {this.paginationPagesElement}

            {showNext && (
              <li
                className={classNames(
                  `${ROOT_CLASSNAME}__item`,
                  `${ROOT_CLASSNAME}__item--next`
                )}
              >
                <Link href={`${hrefBase}?page=${this.nextPage}`}>
                  <a>
                    <Arrow
                      inline={true}
                      className={`${ROOT_CLASSNAME}__next-arrow`}
                      aria-label="Next"
                    />
                  </a>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </article>
    );
  }
}

export default Pagination;
