import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';

import useFreeDeliveryThreshold from '@jpp/hooks/useFreeDeliveryThreshold';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import { IReduxState } from 'common/redux/createStore';
import { ICartLineItem } from 'common/redux/wooCommerce/cart/reducer';
import {
  getCartLineItems,
  getIsAddedToCart,
} from 'common/redux/wooCommerce/cart/selectors';
import { EPageType } from 'common/typings/enums';

import './FreeDeliveryThreshold.scss';

interface IFreeDeliveryThresholdProps {
  className?: string;
}

interface IStoreFreeDeliveryThresholdProps {
  isAddedToBag: boolean;
  cartLineItems: ICartLineItem[];
}

const ROOT_CLASSNAME = 'FreeDeliveryThreshold';

const FreeDeliveryThreshold: React.FunctionComponent<
  IFreeDeliveryThresholdProps
> = ({ className }) => {
  const router = useRouter();
  const { isAddedToBag, cartLineItems } = useSelector<
    IReduxState,
    IStoreFreeDeliveryThresholdProps
  >((state) => ({
    isAddedToBag: getIsAddedToCart(state),
    cartLineItems: getCartLineItems(state),
  }));
  const pagesToNotShow = [
    EPageType.OrderCanceled,
    EPageType.OrderConfirmation,
    EPageType.Checkout,
    EPageType.Cart,
  ];
  const isOnRestrictedPage = pagesToNotShow.some((page) =>
    router.asPath.includes(page)
  );
  const { isLessThanZero, messaging, hasOnlyVirtualProducts } =
    useFreeDeliveryThreshold();

  if (isOnRestrictedPage || hasOnlyVirtualProducts) {
    return null;
  }

  return (
    <article
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--is-active`]:
          isAddedToBag && cartLineItems.length > 0,
      })}
    >
      <Container>
        <Row>
          <Flex
            className={classNames(
              `${ROOT_CLASSNAME}__content-wrapper`,
              `${ROOT_CLASSNAME}__content-wrapper--left`,
              { 'mx-auto align-items-center': isLessThanZero }
            )}
            colMd={6}
          >
            <p
              className={classNames(`${ROOT_CLASSNAME}__content`, {
                'text-left': isLessThanZero,
              })}
            >
              Added to Cart!
            </p>
          </Flex>

          <Flex
            className={`${ROOT_CLASSNAME}__content-wrapper ${ROOT_CLASSNAME}__content-wrapper--right`}
            colMd={6}
          >
            <p className={`${ROOT_CLASSNAME}__content`}>{messaging}</p>
          </Flex>
        </Row>
      </Container>
    </article>
  );
};

export default FreeDeliveryThreshold;
