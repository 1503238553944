import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink/AnchorLink';
import { EMyAccountTab } from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { EPageType, ETheme } from 'common/typings/enums';

import './Breadcrumbs.scss';

interface IBreadcrumbsProps {
  className?: string;
}

const ROOT_CLASSNAME = 'Breadcrumbs';

const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = ({
  className,
}) => {
  const router = useRouter();
  const pagesToNotShow: string[] = [
    EPageType.OrderCanceled,
    EPageType.OrderConfirmation,
    EPageType.Checkout,
    EPageType.Cart,
    EPageType.Auth,
  ];
  const isOnRestrictedPage = pagesToNotShow.some(
    (page) => `/${page}` === router?.asPath || router?.asPath.includes(page)
  );

  const shopPages: string[] = [EPageType.Product, EPageType.Products];
  const eventPages: string[] = [EPageType.Event];
  const blogPages: string[] = [EPageType.Blog];
  const faqPages: string[] = [EPageType.Faq];
  const myAccountPages: string[] = [EPageType.MyAccount];
  const isShopPage = shopPages.some(
    (page) => `/${page}` === router?.asPath || router.asPath.includes(page)
  );
  const isEventPage = eventPages.some(
    (page) =>
      `/${page}` === router?.asPath || router.asPath.includes(`${page}/`)
  );
  const isBarPage = router.asPath.includes(`${EPageType.Bar}/`);
  const isBlogPage = blogPages.some(
    (page) =>
      `/${page}` !== router?.asPath &&
      !router.asPath.includes('?') &&
      router.asPath.includes(page)
  );
  const isFaqPage = faqPages.some(
    (page) =>
      `/${page}s` !== router?.asPath &&
      !router.asPath.includes('?') &&
      router.asPath.includes(page)
  );
  const isMyAccountPage = myAccountPages.some((page) =>
    router.asPath.includes(page)
  );

  if (isOnRestrictedPage) {
    return null;
  }

  let lastSegment = router.asPath.split('/').pop();
  lastSegment = lastSegment ? lastSegment.split('?')[0] : lastSegment;
  const lastSegmentTitle = lastSegment?.replace(/(-)/g, ' ');

  const Divider = (
    <li className={`${ROOT_CLASSNAME}__item`} aria-hidden={true}>
      /
    </li>
  );

  return (
    <Section
      className={classNames(ROOT_CLASSNAME, className)}
      theme={ETheme.TintBeta}
    >
      <Container>
        <Row>
          <Flex>
            <nav className={`${ROOT_CLASSNAME}__nav`}>
              <ul className={`${ROOT_CLASSNAME}__list`}>
                <li className={`${ROOT_CLASSNAME}__item`}>
                  <AnchorLink
                    href={EPageType.Index}
                    className={`${ROOT_CLASSNAME}__link`}
                  >
                    Home
                  </AnchorLink>
                </li>

                {isShopPage && (
                  <>
                    {Divider}
                    <li className={`${ROOT_CLASSNAME}__item`}>
                      <AnchorLink
                        href={`/${EPageType.Shop}`}
                        className={`${ROOT_CLASSNAME}__link`}
                      >
                        Shop
                      </AnchorLink>
                    </li>
                  </>
                )}

                {isEventPage && (
                  <>
                    {Divider}
                    <li className={`${ROOT_CLASSNAME}__item`}>
                      <AnchorLink
                        href={`/${EPageType.Events}`}
                        className={`${ROOT_CLASSNAME}__link`}
                      >
                        Events
                      </AnchorLink>
                    </li>
                  </>
                )}

                {isBarPage && (
                  <>
                    {Divider}
                    <li className={`${ROOT_CLASSNAME}__item`}>
                      <AnchorLink
                        href={`/${EPageType.Experience}`}
                        className={`${ROOT_CLASSNAME}__link`}
                      >
                        Experience
                      </AnchorLink>
                    </li>
                  </>
                )}

                {isBlogPage && (
                  <>
                    {Divider}
                    <li className={`${ROOT_CLASSNAME}__item`}>
                      <AnchorLink
                        className={`${ROOT_CLASSNAME}__link`}
                        href={`/${EPageType.Blog}`}
                      >
                        Blog
                      </AnchorLink>
                    </li>
                  </>
                )}

                {isFaqPage && (
                  <>
                    {Divider}
                    <li className={`${ROOT_CLASSNAME}__item`}>
                      <AnchorLink
                        className={`${ROOT_CLASSNAME}__link`}
                        href={`/${EPageType.Faqs}`}
                      >
                        FAQs
                      </AnchorLink>
                    </li>
                  </>
                )}

                {isMyAccountPage && (
                  <>
                    {Divider}
                    <li className={`${ROOT_CLASSNAME}__item`}>
                      <AnchorLink
                        className={`${ROOT_CLASSNAME}__link`}
                        href={`/${EPageType.MyAccount}/${EMyAccountTab.Details}`}
                      >
                        My Account
                      </AnchorLink>
                    </li>
                  </>
                )}

                {lastSegment && (
                  <>
                    {Divider}
                    <li className={`${ROOT_CLASSNAME}__item`}>
                      {lastSegmentTitle}
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};

export default Breadcrumbs;
