import classNames from 'classnames';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import Hamburger from '@jpp/atoms/Hamburger';
import Icon from '@jpp/atoms/Icon';
import useCartTotals from '@jpp/hooks/useCartTotals';
import ShoppingCart from '@jpp/molecules/ShoppingCartIcon';
import FreeDeliveryThreshold from '@jpp/organisms/FreeDeliveryThreshold';
import MegaMenu from '@jpp/organisms/MegaMenu';
import { handleSetMenuState } from 'common/redux/core/utils';
import { EPageType, ETheme } from 'common/typings/enums';

import { IDispatchPrimaryHeaderProps, IStorePrimaryHeaderProps } from '.';
import './PrimaryHeader.scss';

export interface IPrimaryHeaderProps {
  className?: string;
}

type TPrimaryHeader = IPrimaryHeaderProps &
  IStorePrimaryHeaderProps &
  IDispatchPrimaryHeaderProps;

const ROOT_CLASSNAME = 'PrimaryHeader';

const PrimaryHeader: React.FunctionComponent<TPrimaryHeader> = ({
  isMenuOpen,
  menu,
  setMenuState,
  className,
}) => {
  const { cartContentTotal } = useCartTotals();
  const handleOnHamburgerClick = () =>
    handleSetMenuState(setMenuState, isMenuOpen);

  return (
    <header className={classNames(ROOT_CLASSNAME, className)}>
      <div
        className={classNames(`${ROOT_CLASSNAME}__inner`, {
          [`${ROOT_CLASSNAME}__inner--empty-cart`]: cartContentTotal === 0,
        })}
      >
        <Hamburger
          className={`${ROOT_CLASSNAME}__hamburger`}
          onClick={handleOnHamburgerClick}
          theme={ETheme.TintOmega}
        />

        <div className={`${ROOT_CLASSNAME}__brand-wrapper`}>
          <AnchorLink
            href={EPageType.Index}
            className={`${ROOT_CLASSNAME}__brand-link`}
          >
            <Icon.Logo className={`${ROOT_CLASSNAME}__brand-icon`} />
          </AnchorLink>
        </div>

        <div className={`${ROOT_CLASSNAME}__menu-wrapper`}>
          <MegaMenu menu={menu} className={`${ROOT_CLASSNAME}__mega-menu`} />
        </div>

        <div className={`${ROOT_CLASSNAME}__cart-wrapper`}>
          <ShoppingCart className={`${ROOT_CLASSNAME}__cart`} />
        </div>
      </div>

      <FreeDeliveryThreshold />
    </header>
  );
};

export default PrimaryHeader;
