import classNames from 'classnames';
import React from 'react';

import { FooterBottom } from './components/FooterBottom/FooterBottom';
import { FooterTop } from './components/FooterTop/FooterTop';
import './PrimaryFooter.scss';

export interface IPrimaryFooterProps {
  className?: string;
}

export interface IStorePrimaryFooterProps {
  company_name: string;
  company_slogan: string;
  general_email: string;
  company_number: string;
  address: string;
  footer_heading_one: string;
  footer_heading_two: string;
  footerMenuOne: Core.IMenuItem[];
  footerMenuTwo: Core.IMenuItem[];
}

type TPrimaryFooter = IPrimaryFooterProps & IStorePrimaryFooterProps;

export const PrimaryFooter: React.FunctionComponent<TPrimaryFooter> = ({
  className,
  company_name,
  company_slogan,
  general_email,
  company_number,
  address,
  footer_heading_one,
  footer_heading_two,
  footerMenuOne,
  footerMenuTwo,
}) => {
  return (
    <footer className={classNames('PrimaryFooter', className)}>
      <FooterTop
        className={'PrimaryFooter__top'}
        address={address}
        company_number={company_number}
        companySlogan={company_slogan}
        generalEmail={general_email}
        footer_heading_one={footer_heading_one}
        footer_heading_two={footer_heading_two}
        footerMenuOne={footerMenuOne}
        footerMenuTwo={footerMenuTwo}
      />
      <FooterBottom
        className={'PrimaryFooter__bottom'}
        companyName={company_name}
      />
    </footer>
  );
};
