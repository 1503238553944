import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import useIsLoggedIn from '@jpp/hooks/useIsLoggedIn';
import useToggle from '@jpp/hooks/useToggle';
import { AuthModal } from '@jpp/organisms/Modal';
import { EMyAccountTab } from '@jpp/organisms/MyAccount/TabGroup/MyAccountTabGroup';
import { EAuthPage } from '@jpp/templates/Auth/Auth';
import { EPageType } from 'common/typings/enums';

const ROOT_CLASSNAME = 'MegaMenuItem';

interface IMegaMenuAuthItemProps {
  className?: string;
  linkClassName: string;
  element?: 'li' | 'div';
}

const MegaMenuAuthItem: React.FunctionComponent<IMegaMenuAuthItemProps> = ({
  className,
  element = 'li',
  linkClassName,
}) => {
  const router = useRouter();
  const [isModalVisible, toggleIsModalVisible] = useToggle(false);
  const isLoggedIn = useIsLoggedIn();
  const Tag = element as unknown as React.ComponentType<any>;
  const shouldNotOpenModal =
    router.asPath.includes('auth/logout') ||
    router.asPath.includes('auth/login');

  const handleButtonOnClick = () => {
    if (shouldNotOpenModal) {
      router.push(`/${EPageType.Auth}/${EAuthPage.Login}`);
    } else {
      toggleIsModalVisible();
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <>
          <Tag
            className={classNames(
              className,
              ROOT_CLASSNAME,
              `${ROOT_CLASSNAME}--auth`
            )}
          >
            <div className={`${ROOT_CLASSNAME}__item-wrapper`}>
              <AnchorLink
                className={classNames(`${ROOT_CLASSNAME}__link`, linkClassName)}
                href={`/${EPageType.MyAccount}/${EMyAccountTab.Details}`}
                behaviour="router"
              >
                My&nbsp;Account
              </AnchorLink>
              <Link href={`/${EPageType.Auth}/logout`}>
                <a className={`${ROOT_CLASSNAME}__link--logout display-none`}>
                  (Logout)
                </a>
              </Link>
            </div>
          </Tag>
        </>
      ) : (
        <>
          <Tag
            role="button"
            onClick={handleButtonOnClick}
            className={classNames(
              className,
              ROOT_CLASSNAME,
              `${ROOT_CLASSNAME}--auth`
            )}
          >
            <span
              className={classNames(`${ROOT_CLASSNAME}__link`, linkClassName)}
            >
              Login
            </span>
          </Tag>

          {isModalVisible && (
            <AuthModal
              isVisible={isModalVisible}
              className={`${ROOT_CLASSNAME}__modal`}
              onClose={toggleIsModalVisible}
              bodyOverflow={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default MegaMenuAuthItem;
