import { FormikBag, withFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  RECAPTCHA_DEFAULT_VALUE,
  RECAPTCHA_YUP,
} from '@jpp/hooks/useGoogleReCaptcha';
import {
  ECountryCode,
  getShippingCountryCode,
} from '@jpp/organisms/CheckoutForm/utils';
import { getAuth } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { getCurrentUser } from 'common/redux/rootActions';
import axios from 'common/utils/axios/internal';
import { PHONE_REGEX } from 'common/utils/shared';
import {
  invalidMessageMap,
  requiredMessageMap,
} from 'common/utils/shared/validation';

import MyAccountDetailsForm, {
  IMyAccountDetailsFormValues,
  IStoreMyAccountDetailsFormProps,
  TMyAccountDetailsForm,
  TMyAccountDetailsFormFields,
} from './MyAccountDetailsForm';

const mapStateToProps = (
  state: IReduxState
): IStoreMyAccountDetailsFormProps => ({
  user: getAuth(state),
});

const mapDispatchToProps = {
  onGetUser: getCurrentUser,
};

const mapPropsToValues = ({
  user,
}: TMyAccountDetailsForm): IMyAccountDetailsFormValues => {
  const { billing, shipping } = user || {};

  const isSameAsShipping = isEqual(
    {
      address_1: billing?.billing_address_1,
      address_2: billing?.billing_address_2,
      city: billing?.billing_city,
      company: billing?.billing_company,
      country: billing?.billing_country,
      state: billing?.billing_state,
      postcode: billing?.billing_postcode,
    },
    {
      address_1: shipping?.shipping_address_1,
      address_2: shipping?.shipping_address_2,
      city: shipping?.shipping_city,
      company: shipping?.shipping_company,
      country: shipping?.shipping_country,
      state: shipping?.shipping_state,
      postcode: shipping?.shipping_postcode,
    }
  );

  return {
    firstName: user?.first_name,
    lastName: user?.last_name,
    phoneNumber: user?.phone,
    marketing_opt_in: user?.marketing_emails,
    sameAsShipping: isSameAsShipping,

    billing_address_1: billing?.billing_address_1 || '',
    billing_address_2: billing?.billing_address_2 || '',
    billing_city: billing?.billing_city || '',
    billing_company: billing?.billing_company || '',
    billing_country: billing?.billing_country
      ? ECountryCode[billing?.billing_country] || ECountryCode.GB
      : '',
    billing_state: billing?.billing_state || '',
    billing_postcode: billing?.billing_postcode || '',

    billing_first_name: billing?.billing_first_name || '',
    billing_last_name: billing?.billing_last_name || '',

    shipping_address_1: shipping?.shipping_address_1 || '',
    shipping_address_2: shipping?.shipping_address_2 || '',
    shipping_city: shipping?.shipping_city || '',
    shipping_company: shipping?.shipping_company || '',
    shipping_country: shipping?.shipping_country || '',
    shipping_state: shipping?.shipping_state || '',
    shipping_postcode: shipping?.shipping_postcode || '',

    shipping_first_name: shipping?.shipping_first_name || '',
    shipping_last_name: shipping?.shipping_last_name || '',

    ...RECAPTCHA_DEFAULT_VALUE,
  };
};

const validationSchema: Yup.ObjectSchema<
  Yup.Shape<
    TMyAccountDetailsFormFields | undefined,
    TMyAccountDetailsFormFields
  >
> = Yup.object().shape({
  firstName: Yup.string().required(requiredMessageMap.firstName),
  lastName: Yup.string().required(requiredMessageMap.lastName),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, invalidMessageMap.phone)
    .required(requiredMessageMap.phone),
  sameAsShipping: Yup.boolean(),
  marketing_opt_in: Yup.boolean(),
  billing_address_1: Yup.string(),
  billing_address_2: Yup.string(),
  billing_city: Yup.string(),
  billing_company: Yup.string(),
  billing_country: Yup.string(),
  billing_state: Yup.string(),
  billing_postcode: Yup.string(),
  billing_first_name: Yup.string(),
  billing_last_name: Yup.string(),

  shipping_address_1: Yup.string(),
  shipping_address_2: Yup.string(),
  shipping_city: Yup.string(),
  shipping_company: Yup.string(),
  shipping_country: Yup.string(),
  shipping_state: Yup.string(),
  shipping_postcode: Yup.string(),
  shipping_first_name: Yup.string(),
  shipping_last_name: Yup.string(),

  ...RECAPTCHA_YUP,
});

const handleSubmit = async (
  fields: IMyAccountDetailsFormValues,
  {
    props,
    setFieldError,
    setStatus,
  }: FormikBag<TMyAccountDetailsForm, IMyAccountDetailsFormValues>
) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    sameAsShipping,
    marketing_opt_in,
    billing_address_1,
    billing_address_2,
    billing_city,
    billing_company,
    billing_country,
    billing_first_name,
    billing_last_name,
    billing_postcode,
    billing_state,
    shipping_address_1,
    shipping_address_2,
    shipping_city,
    shipping_company,
    shipping_first_name,
    shipping_last_name,
    shipping_postcode,
    shipping_state,
    token,
  } = fields || {};
  const { user, onGetUser } = props;

  const shipping = {
    first_name: shipping_first_name,
    last_name: shipping_last_name,
    address_1: shipping_address_1,
    address_2: shipping_address_2,
    state: shipping_state,
    city: shipping_city,
    company: shipping_company,
    country: ECountryCode.GB,
    postcode: shipping_postcode,
  };

  try {
    const formData = {
      token,
      first_name: firstName,
      last_name: lastName,
      billing: sameAsShipping
        ? {
            ...shipping,
            phone: phoneNumber,
          }
        : {
            first_name: billing_first_name,
            last_name: billing_last_name,
            address_1: billing_address_1,
            address_2: billing_address_2,
            state: billing_state,
            city: billing_city,
            company: billing_company,
            country: getShippingCountryCode(billing_country),
            postcode: billing_postcode,
            phone: phoneNumber,
          },
      shipping,
      meta: {
        marketing_emails: marketing_opt_in,
      },
    };
    await axios.put(`/customer/${user.id}`, formData);
    window.scrollTo(0, 0);
    await setStatus('Your information has been updated!');
    await onGetUser();
  } catch (error) {
    const { response = {} } = error;
    const {
      data: { message },
    } = response;
    window.scrollTo(0, 0);
    await setFieldError('errorMessage', message);
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik<TMyAccountDetailsForm, IMyAccountDetailsFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    enableReinitialize: true,
  })(MyAccountDetailsForm)
);
