import { connect } from 'react-redux';

import { getMenu, getOptions } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';
import { EMenuType } from 'common/typings/enums';

import {
  IPrimaryFooterProps,
  IStorePrimaryFooterProps,
  PrimaryFooter,
} from './PrimaryFooter';

const mapStateToProps = (state: IReduxState): IStorePrimaryFooterProps => {
  const {
    company_name,
    company_slogan,
    general_email,
    address,
    company_number,
    footer_heading_one,
    footer_heading_two,
  } = getOptions(state);
  return {
    company_name,
    company_slogan,
    general_email,
    company_number,
    address,
    footer_heading_one,
    footer_heading_two,
    footerMenuOne: getMenu(state, EMenuType.FooterMenuOne),
    footerMenuTwo: getMenu(state, EMenuType.FooterMenuTwo),
  };
};

export default connect<IStorePrimaryFooterProps, never, IPrimaryFooterProps>(
  mapStateToProps
)(PrimaryFooter);
